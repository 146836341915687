$back-to-top-bg           : $primary;
$back-to-top-color        : white;
$back-to-top-border-width : null;
$back-to-top-opacity      : 1;
$back-to-top-radius       : 50%;

.back-to-top {
  $btn-dim   : 50px;
  $position  : "fixed";
  $alignment : "center";

  @if $position == "fixed" {
    position : fixed;
    bottom   : #{4 * $grid-gutter-width};
    right    : #{$grid-gutter-width};

    @include media-breakpoint-up(md) {
      bottom : #{2* $grid-gutter-width};
    }
  } @else {
    position : relative;
  }

  transition : all 0.5s linear;
  z-index    : 1020; // lies behind the cookie banner


  &[data-mode="animated"] {
    opacity : 0;

    &.hidden { display : none; }

    &.show { opacity : 1; }
  }

  .container {
    position : relative;
  }

  a {
    &.btn {
      @include flex("center");
      position         : absolute;
      bottom           : 0;

      @if $alignment == "center" {
        left      : 50%;
        right     : auto;
        transform : translate(-50%, 50%);
      } @else {
        right     : 0;
        transform : translateY(50%);
      }

      @if ($back-to-top-border-width) {
        border : $back-to-top-border-width solid $back-to-top-color;
      } @else {
        border : none;
      }

      width            : $btn-dim;
      height           : $btn-dim;
      color            : $back-to-top-color;
      background-color : $back-to-top-bg;
      border-radius    : $back-to-top-radius;
      opacity          : $back-to-top-opacity;
      transition       : all 0.25s linear;

      &:hover { opacity : 1; }
    }

    @if $position == "fixed" {
      &.btn-top {
        border : 1px solid fade_out($primary, 0.1);
        color  : fade_out($primary, 0.1);
      }

      &.btn-bottom {
        border : 1px solid fade_out($primary, 0.1);
        color  : fade_out($primary, 0.1);

        @include media-breakpoint-up(md) {
          border : 1px solid fade_out(white, 0.1);
          color  : fade_out(white, 0.1)
        }
      }
    }
  }
}
