/*
 *  General form styles
 */
$form-margin                   : 3rem auto 0;
$form-container-width          : 75%;
$form-field-border-color       : $gray-700;
$form-field-focus-border-color : $primary;

.form-section {
  .page-content,
  .form-container {
    margin : $form-margin;

    @include media-breakpoint-up(lg) {
      max-width : $form-container-width;
    }
  }
}

form {
  .btn { min-width : 100px; }

  &.form-theme {
    .form-control {}

    .form-checkboxes {
      display        : flex;
      flex-direction : column;
      gap            : 0.35rem;

      .label {
        @include font-size($h5-font-size);
        font-weight : bold;
      }

      .checkboxes {
        input[type=checkbox]:checked + label:before {
          line-height : inherit;
          font-size   : inherit;
        }

        label {
          display      : inline-flex !important;
          align-items  : center;
          padding-left : 2.25rem;

          &::before {
            width            : $form-check-input-width;
            height           : $form-check-input-width;
            background-color : $form-check-input-bg;
            border           : $form-check-input-border;
            border-radius    : 0;
          }
        }
      }
    }

    .hide-title h3 {
      visibility : hidden;

      @include media-breakpoint-down(lg) {
        margin  : 0;
        padding : 0;
        height  : 0;
      }
    }
  }
}

.form-field {
  margin-bottom : 1rem;

  &:not(.form-floating) {
    .label {
      margin-bottom : 0.25rem;
      color         : $text-muted;
    }
  }

  &.form-floating {
    [type="text"], textarea, select {
      &::placeholder { color : transparent !important; }
    }
  }
}

.form-control {
  &:focus,
  &:hover {}

  &.long { min-height : 190px; }

  &.large {
    padding-top    : 0.75rem;
    padding-bottom : 0.75rem;
  }

  [type="text"], textarea, select {
    border        : none;
    border-bottom : 2px solid fade-out($form-field-border-color, 0.75);

    &:focus,
    &:not(:placeholder-shown) {
      border-color : fade-out($form-field-focus-border-color, 0.5);
    }
  }
}

.form-checkbox-field {
  @include flex('stretch', 'flex-start');

  .form-extra-wrapper {
    padding-left : 0;
  }
}

.form-check {
  display : inline-block;

  label {
    @include visually-hidden;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  //-webkit-box-shadow      : 0 0 0 1000px lighten($primary, 10%) inset;
  //-webkit-text-fill-color : $body-color;
}

.button-group {
  display : flex;

  button {
    display    : block;
    width      : 100%;
    flex-basis : 50%;
    height     : calc(2.25rem + #{$grid-gutter-width} / 2 + 2px);

    & + button {
      margin-left : 10px;
    }
  }
}
