$font-folder : "../fonts/Avenir/";

@font-face {
  font-family : "Avenir LT W01 35 Light";
  src         : url("#{$font-folder}Fonts/1475496/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475496/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 35 Light Oblique";
  src         : url("#{$font-folder}Fonts/1475502/a59168c1-917d-4de9-a244-0316c057c357.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475502/6dc0e7d8-9284-44e1-8f05-984a41daa3a4.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 45 Book";
  src         : url("#{$font-folder}Fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 45 Book Oblique";
  src         : url("#{$font-folder}Fonts/1475514/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475514/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 55 Roman";
  src         : url("#{$font-folder}Fonts/1475520/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475520/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 55 Oblique";
  src         : url("#{$font-folder}Fonts/1475526/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475526/cdda031e-26e9-4269-83d1-5a218caa10db.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 65 Medium";
  src         : url("#{$font-folder}Fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 65 Medium Oblique";
  src         : url("#{$font-folder}Fonts/1475538/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475538/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 85 Heavy";
  src         : url("#{$font-folder}Fonts/1475550/3c210c80-960f-4684-850b-25390b4d08af.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475550/cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 95 Black";
  src         : url("#{$font-folder}Fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W01 95 Black Olique";
  src         : url("#{$font-folder}Fonts/1475562/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"),
  url("#{$font-folder}Fonts/1475562/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff");
}

@font-face {
  font-family : "Avenir LT W05 85 Heavy";
  src         : url("#{$font-folder}Fonts/5687390/0d51f252-d47b-421f-8ffe-eb417dce1b53.woff2") format("woff2"),
  url("#{$font-folder}Fonts/5687390/b34d406c-b12e-4b82-892e-1bfab6517cce.woff") format("woff");
}
