// @use sass:map;
@use "partials/custom-content-menu" as ccm;

.floating-action-menu {
  --fab-menu-width      : 4rem;
  --fab-menu-offset-top : 26vh;

  @include set-position("left", "fixed");

  top                   : 25%;
  bottom                : 25%;
  z-index               : 9500;

  .floating-action-container {
    position : relative;

    .btn-group {
      position : static;

      & > .btn {
        @include flex("center", "center", "inline-flex");

        width  : 3rem;
        height : 3rem;
        color  : $light;
      }
    }

    .dropdown-container {
      padding : 0 3rem 3rem;

      .dropdown-title {
        @include ccm.dropdown-title($h1-font-family, $h4-font-size, $primary);
      }
    }

    .dropdown-toggle {
      @include ccm.dropdown-toggle($primary);
    }

    i { pointer-events : none; }

    .dropdown-menu {
      max-height : calc(100vh - var(--fab-menu-offset-top));
      width      : max-content;
      max-width  : calc(100vw - var(--fab-menu-width));
      overflow-y : auto;
      transform  : translate(3.5rem, 0) !important;
      padding    : 0;

      @include media-breakpoint-up(md) {
        &.page,
        &.form {
          max-width : calc(100vw - 2 * var(--fab-menu-width));
        }
      }

      @include media-breakpoint-up(lg) {
        &.form {
          max-width : calc(#{map-get($container-max-widths, lg)});
        }
      }

    }
  }
}
