@use "partials/custom-content-menu" as ccm;

.bottom-mobile-menu {
  $bottom-menu-height : 54px;

  .bottom-mobile-container {
    position : relative;

    .btn-group {
      position : static;
      flex     : 1 1 auto;

      & > .btn {
        width     : 100%;
        padding   : 0.5rem 1rem;
        font-size : 1.5rem;
        color     : $light;
      }
    }

    .dropdown-container {
      padding : 0 1.5rem 1.5rem;

      .dropdown-title {
        @include ccm.dropdown-title($h1-font-family, $h4-font-size, $primary);
      }
    }

    .dropdown-toggle {
      @include ccm.dropdown-toggle($primary);
    }

    .dropdown-menu {
      width      : 100%;
      left       : 0 !important;
      max-height : calc(80vh - #{$bottom-menu-height});
      overflow   : hidden auto;
      padding    : 0;

      &.menu {
        height : 100vh;

        .dropdown-container {
          @include flex("flex-end", "center");

          padding : 0 0 3rem 0;
          height  : 100%;
        }

        .dropdown-menu {
          position  : static !important;
          transform : unset !important;
        }
      }
    }
  }
}
