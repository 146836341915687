@mixin DEVICE_HOVER() {
  @at-root {
    .hover-hover & {
      @content
    }
  }
  @media (hover : hover) {
    @content
  }
}

@mixin DEVICE_NO-HOVER() {
  @at-root {
    .hover-none & {
      @content
    }
  }
  @media (hover : none) {
    @content
  }
}

@mixin DEVICE_TOUCH() {
  @at-root {
    .pointer-coarse & {
      @content
    }
  }
  @media (pointer : coarse) {
    @content
  }
}

@mixin DEVICE_MOUSE() {
  @at-root {
    .pointer-fine & {
      @content
    }
  }
  @media (pointer : fine) {
    @content
  }
}

/*
 use this mixin carefully - combinations like "hover: hover && pointer: coarse" are possible
 your styles should result in a useable application - even if neither MOBILE nor DESKTOP matches
 */
@mixin DEVICE_MOBILE {
  @at-root {
    .hover-none.pointer-coarse & {
      @content
    }
  }
  @media (hover : none) and (pointer : coarse) {
    @content
  }
}

/*
 use this mixin carefully - combinations like "hover: hover && pointer: coarse" are possible
 your styles should result in a useable application - even if neither MOBILE nor DESKTOP matches
 */
@mixin DEVICE_DESKTOP {
  @at-root {
    .hover-hover.pointer-fine & {
      @content
    }
  }
  @media (hover : hover) and (pointer : fine) {
    @content
  }
}
