.features {
  $features-offset : 5px;

  position         : relative;
  margin-top       : 0;
  padding-top      : $features-offset;

  &.slide-down {
    .card-header {
      top: 0;
    }

    .card-footer {
      display: none;
    }

    .card {
      &:hover, &:focus, &:active {
        .card-header {
          top: 50%;
        }
      }
    }
  }

  &.slide-in-out {
    .card-header {
      left : 0;
    }

    .card-footer {
      left : 200%;
    }

    .card {
      &:hover, &:focus, &:active {
        .card-header {
          left : 200%;
        }

        .card-footer {
          left : 0;
        }
      }
    }
  }

  .card {
    background : transparent;
    border     : 0;

    @include hover-scale-image(1.25, 2.5s, true);

    .card-img {
      filter : grayscale(100%);
    }

    &:hover, &:focus, &:active {
      .card-img {
        filter : grayscale(0%);
      }

      .card-header,
      .card-footer {
        color : white;
      }

      .card-header {}
      .card-footer {}
    }
  }

  .card-content {
    flex-direction : column;
  }

  .card-header,
  .card-footer {
    position         : relative;
    color            : rgba(255, 255, 255, 0.8);
    background-color : transparent;
    text-align       : center;
    text-shadow      : -1px -1px 1px #333;
    border           : 0;

    @include transition(top, left, color);

    h5 {
      font-family      : $font-family-base;
      font-size   : 2rem;
      font-weight : bolder;
    }
  }

  .card-header {}

  .card-footer {
    @include set-position("bottom");

    border-top  : 0;
    background  : none;
    left        : 200%;
    font-size   : 1.75rem;
    font-weight : bolder;
  }


  .card-overlay {
    $gradient-base : white;
    background     : linear-gradient(0deg, fade-out($gradient-base, 0.5) 0%, fade-out($gradient-base, 0.75) 25%, fade-out($gradient-base, 1) 50%);
  }
}
