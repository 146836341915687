// Original bootstrap value
$border-radius-base             : 0.25rem;

// Colors
//$blue:    #007bff;
//$indigo:  #6610f2;
//$purple:  #6f42c1;
//$pink:    #e83e8c;
//$red:     #dc3545;
//$orange:  #fd7e14;
//$yellow:  #ffc107;
//$green:   #28a745;
//$teal:    #20c997;
//$cyan:    #17a2b8;

// Theme Colors
$primary                        : rgb(141, 181, 51);
$secondary                      : rgb(56, 179, 223);
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
$dark                           : rgb(87, 87, 86);

// Settings
$enable-smooth-scroll           : false;

$min-contrast-ratio             : 3;

// Components
$border-radius                  : 0;
$border-radius-sm               : 0;
$border-radius-lg               : 0;

// Typography
// $font-size-base                 : 1rem;
// $font-size-headline-base        : $font-size-base;

$body-color                     : #707070;
$body-font-size                 : 1.15rem;

// Buttons
$btn-font-size                  : $body-font-size;
$btn-focus-box-shadow           : none;
$btn-focus-width                : 0;

// Cards
$card-bg                        : transparent;
$card-border-radius             : $border-radius;
$card-inner-border-radius       : $border-radius;
$card-overlay-bg                : transparent;
$card-deck-margin               : 2.5px;

// Modal
$modal-content-bg               : transparent;
$modal-header-border-width      : 0;
// $modal-header-border-color      : transparent;

// Dropdown
// $dropdown-link-active-bg   : white;
$dropdown-spacer                : 0;

// Offcanvas
$offcanvas-bg-color             : white;
$offcanvas-padding-y            : 2.5rem;

// Forms
$input-bg                       : fade-out($primary, 0.75);
$input-border-color             : fade-out($primary, 0.5);
$form-check-input-width         : 1.5em;
$form-check-input-border-radius : 0;
$form-check-input-border        : 1px solid $input-border-color;
// $form-check-input-bg            : fade-out($primary, 0.75);

// Navbar
$navbar-brand-margin-end        : 0;
$navbar-brand-padding-y         : 0;
$navbar-light-active-color      : $primary;

@import "~bootstrap/scss/variables";
