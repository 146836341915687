/* Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  // font-family : $font-family-headline;
}

h1, .h1 {
  font-family : $h1-font-family;
  font-weight : 900;
  @include font-size($h2-font-size);
}

h2, .h2 {
  font-family   : $h2-font-family;
  margin-bottom : 1.5rem;

  strong { font-weight : 900; }
}

h3, .h3 {
  font-family   : $h3-font-family;
  margin-bottom : 1rem;

  strong { font-weight : 900; }
}

h4, .h4 {}

h5, .h5 {
  font-family : $h5-font-family;

  &.card-heading {
    @include font-size(1.25 * $h5-font-size);
  }
}

.btn-link {
  padding : 0;
}

/* Page Structure */
body {
  display        : flex;
  flex-direction : column;
  min-height     : 100vh;
  max-width      : 100vw;
  overflow-x     : hidden;
  opacity        : 0;
  visibility     : hidden;

  &.loaded {
    opacity    : 1;
    visibility : visible;
    @include transition(visibility .5s, opacity .5s);
  }
}

@if $header-shadow {
  header {
    box-shadow : 0 0 20px 5px $header-shadow-color;

    .nav-open & {
      box-shadow : 0 0 5px 0 $header-shadow-color;
    }

    &.is-sticky {
      box-shadow : 0 0 10px 0 $header-shadow-color;
    }
  }
}

@if $header-transition {
  header {
    @include transition(box-shadow, transform);

    #main-header {
      @include transition(padding, height);
    }

    &.is-sticky {
      .main-nav {
        .navbar-brand {
          img {
            // max-width : 0.66 * $logo-mobile-width !important;

            @include media-breakpoint-up($grid-float-breakpoint) {
              max-width : 0.75 * $logo-desktop-width !important;
            }
          }
        }
      }

      @include media-breakpoint-down($grid-float-breakpoint) {
        .navbar-toggler { padding : 0; }
      }
    }

    &.hidden {
      transform: translateY(-100%);
    }

    .main-nav {
      padding-top    : 0;
      padding-bottom : 0;

      .navbar-brand {
        min-width : 150px;

        img {
          @include transition(max-width, width);
        }
      }
    }
  }
}

header {
  max-width : 100vw;
  width     : 100%;

  z-index   : 1000;


  &.header-sticky {
    // position : sticky;
    position : fixed;
  }

  &.opaque { background-color : $body-bg; }

  #main-topbar {
    --main-topbar-height : 2.5rem;
    overflow             : hidden;

    @include transition(margin);

    &.hidden {
      margin-top : calc(-1 * var(--main-topbar-height));
    }

    @include all-containers {
      display     : flex;
      align-items : center;
      gap         : 1rem;
    }

    .nav-link {
      padding : 0 $navbar-nav-link-padding-x;
      color   : $topbar-nav-link-color;
      border  : none;

      &:hover, &:focus, &:active {
        color : $topbar-nav-link-hover-color;
      }
    }

    .langswitcher {
      --langswitcher-nav-link-color        : #{$topbar-nav-link-color};
      --langswitcher-nav-link-hover-color  : #{$topbar-nav-link-hover-color};
      --langswitcher-nav-link-border-color : #{$topbar-nav-link-color};
    }
  }

  .fixed-nav-social {
    @include set-position('right');
    top    : 25vh;
    bottom : auto;

    @include media-breakpoint-up($grid-float-breakpoint) {
      position : fixed;
      top      : 15%;
    }

    .nav-link {
      font-size      : 1.5rem;
      padding-top    : 0.25rem;
      padding-bottom : 0.25rem;
    }
  }

  .main-nav {
    @include visibility('visible', 0.5s);

    &.invisible {
      @include visibility('invisible');
    }

    .navbar-brand {
      min-width : $logo-mobile-width;

      @include media-breakpoint-up($grid-float-breakpoint) {
        min-width : $logo-desktop-width;
      }

      img {
        flex      : 1 0 0;
        max-width : $logo-mobile-width;

        @include media-breakpoint-up($grid-float-breakpoint) {
          max-width : $logo-desktop-width;
        }
      }
    }

    .offcanvas-header {
      box-shadow : 0 0 3px 0 darken($primary, 25%);
    }
  }
}

#page-banner {
  margin-top : $header-mobile-height;

  // @include transition(margin);

  @include media-breakpoint-up($grid-float-breakpoint) {
    margin-top : $header-height;
  }

  .is-sticky + & {
    margin-top : 3/4 * $header-height;
  }
}

section.fullscreen {
  width  : 100vw;
  height : 100vh;
}

main {
  position       : relative;
  display        : flex;
  flex-direction : column;
  flex           : 1 0 auto;

  & > section {
    position        : relative;
    background-size : cover;
    // padding-top     : 3rem;
    padding-bottom  : 3rem;

    @include media-breakpoint-up($grid-float-breakpoint) {
      // padding-top    : 6rem;
      padding-bottom : 6rem;
    }
  }

  .main-content {
    position : relative;
    flex     : 1 0 auto;
    padding  : 3rem 0;
    overflow : hidden;

    @include media-breakpoint-up(lg) {
      padding : 6rem 0;
    }
  }
}

footer {
  flex-shrink : 0;

  .copyright {
    padding          : 1rem 0;
    text-align       : right;
    background-color : #3D3D3D;
    color            : $gray-400;
  }

  .nav {
    .nav-link {
      text-align : left;
    }
  }

  .social.nav {
    @include media-breakpoint-down(md) {
      justify-content : center;
    }

    .nav-link {
      color : white;

      &:hover, &:focus, &:active {
        color : darken(white, 25%);
      }
    }

    .nav-item + .nav-item {
      .nav-link {
        padding-left : 0;
      }
    }
  }
}

#overlay {
  @include set-position("full");

  height           : 99999px;
  background-color : fade-out(black, 0.5);
  opacity          : 1;
  transition       : opacity 0.25s;
  z-index          : 9000;

  &:not(.show) {
    height         : 0;
    opacity        : 0;
    pointer-events : none;
  }
}

/* Flex Objects */
#flex-blog,
#flex-contacts {
  article {
    & + article {
      padding-top : 3rem;
      margin-top  : 3rem;
      border-top  : 1px solid $border-color;
    }
  }
}
