.offcanvas {
  .offcanvas-header {
    padding-top    : 1.5rem;
    padding-bottom : 1.5rem;

    header.is-sticky & {
      padding-top    : 0.75rem;
      padding-bottom : 0.75rem;

      .logo-image {
        max-width : 0.7 * $logo-mobile-width !important;
      }
    }
  }

  .offcanvas-body {
    &::-webkit-scrollbar { width : 0.5rem; }

    &::-webkit-scrollbar-thumb {
      background    : darken($primary, 15%);
      border-radius : 1.75rem;
    }

    &::-webkit-scrollbar-track {
      background : lighten($dark, 15%);
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-lg {
    .offcanvas {
      --bs-bg-opacity : 0;
    }

    .offcanvas-body {
      justify-content : center;
    }
  }
}
