@import "../../../../node_modules/bootstrap/scss/mixins";

@mixin dropdown-title($font-family, $font-size, $border-color) {
  font-family  : $font-family;
  padding-top  : 1.5rem;
  padding-left : 1rem;
  border-left  : 2px solid $border-color;
  white-space  : nowrap;
  @include font-size($font-size);
}

@mixin dropdown-toggle($active-color) {
  &::after {
    display : none;
    content : none;
  }

  &.show {
    background-color : white;
    border-color     : white;
    color            : $active-color;

    .icon-inactive { display : none; }

    .icon-active { display : block; }
  }

  .icon-active { display : none; }
}
