@use "partials/navbar-toggler" as *;

$toggler-color         : $nav-link-color;

.side-nav {
  @include media-breakpoint-down($grid-float-breakpoint) {
    display : none !important;
  }
}

.navbar-brand {
  figure {
    margin-bottom : 0;
  }
}

.nav-item {
  position : relative;
}

.nav-link {
  text-align : center;
}

.dropdown-toggle {
  @include flex("center");
}

.dropdown-menu {
  border        : 0;
  border-radius : 0;

  .dropdown-item {

    @include media-breakpoint-down($grid-float-breakpoint) {
      text-align : center;

      &::before,
      &::after {
        display : inline-block;
        content : "-";
      }
    }

    @include media-breakpoint-up($grid-float-breakpoint) {
      text-align : initial;
    }
  }
}

@include media-breakpoint-down($grid-float-breakpoint) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-fluid {
    flex-direction : column;
  }

  @include NAVBAR-TOGGLER($toggler-color);
}

/*
 * Different slide animation types for the bootstrap mobile navigation
 * Add one of these classes to the navbar classes in navigation.html.twig:
 * nav-slide-down, nav-slide-left, nav-slide-right
 */
@include media-breakpoint-down($grid-float-breakpoint) {
  [class*="nav-slide-"] {
    .navbar-brand,
    .navbar-toggler {
      z-index : 1000;
    }

    .navbar-collapse {
      position         : fixed;
      top              : 0;
      bottom           : 0;
      left             : 0;
      width            : 80%;

      padding          : $grid-gutter-width/2;
      padding-top      : $header-mobile-height + $grid-gutter-width/2;
      background-color : white; // $primary;
      z-index          : 900;
    }
  }

  .nav-slide-down {
    .navbar-collapse {
      width : 90%;
      left  : 5%;
      right : 5%;
    }
  }

  .nav-slide-right,
  .nav-slide-left {
    .collapse:not(.show) {
      display : block;
    }

    .navbar-collapse {
      height : 100% !important;
    }

    .collapsed + .navbar-collapse {
      &.collapsing {
        overflow : visible;
      }
    }
  }

  .nav-slide-right {
    .navbar-collapse {
      left       : unset;
      right      : 0;
      transition : right 0.5s ease-in-out;
    }

    .collapsed + .navbar-collapse {
      right : -100vw;
    }
  }

  .nav-slide-left {
    .navbar-collapse {
      left       : 0;
      right      : unset;
      transition : left 0.5s ease-in-out;
    }

    .collapsed + .navbar-collapse {
      left : -100vw;
    }
  }
}
