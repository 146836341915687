$scrolldown-color        : $body-color;
$scrolldown-light-color  : $light;
$scrolldown-dark-color   : $dark;

$srolldown-bg            : $primary;

$scrolldown-border-width : 2px;

$scrollown-dim           : 50px;

.scrolldown {
  --scrolldown-color              : #{$scrolldown-color};
  --scrolldown-hover-color        : var(--scrolldown-color);
  --scrolldown-bg                 : #{$srolldown-bg};
  --scrolldown-hover-bg           : #{darken($srolldown-bg, 15%)};
  --scrolldown-border-color       : transparent;
  --scrolldown-border-hover-color : transparent;

  &.scrolldown-light {
    --scrolldown-bg       : #{$scrolldown-light-color};
    --scrolldown-hover-bg : #{darken($scrolldown-light-color, 5%)};
    --scrolldown-color    : #{$scrolldown-dark-color};
  }

  &.scrolldown-dark {
    --scrolldown-bg       : #{$scrolldown-dark-color};
    --scrolldown-hover-bg : #{darken($scrolldown-dark-color, 5%)};
    --scrolldown-color    : #{$scrolldown-light-color};
  }

  &.scrolldown-outline {
    --scrolldown-bg           : transparent;
    --scrolldown-hover-bg     : var(--scrolldown-border-color);
    --scrolldown-border-color : var(--scrolldown-color);

    &.scrolldown-light {
      --scrolldown-color       : #{$scrolldown-light-color};
      --scrolldown-hover-color : #{$scrolldown-dark-color};
    }

    &.scrolldown-dark {
      --scrolldown-color       : #{$scrolldown-dark-color};
      --scrolldown-hover-color : #{$scrolldown-light-color};
    }
  }

  @include set-position('bottom');
  @include flex("center");
  flex-direction                  : column;
  padding                         : $grid-gutter-width 0;
  color                           : var(--scrolldown-color);
  z-index                         : 900;

  a {
    @include flex("center");
    width            : $scrollown-dim;
    height           : $scrollown-dim;
    border-radius    : 50%;
    background-color : var(--scrolldown-bg);
    border           : $scrolldown-border-width solid var(--scrolldown-border-color);
    color            : var(--scrolldown-color);
    text-decoration  : none;

    &:hover, &:focus, &:active {
      color            : var(--scrolldown-hover-color);
      background-color : var(--scrolldown-hover-bg);
      border-color     : var(--scrolldown-border-hover-color);
    }
  }

  .scrolldown-text {
    display     : none;
    margin-top  : 1rem;
    text-shadow : 1px 1px 2px black;

    @include media-breakpoint-up(md) {
      display : block;
    }
  }
}
