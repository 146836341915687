$rounded-buttons       : false;
$fullscreen-position-x : 75%;
$fullscreen-position-y : 50%;

.carousel-container {
  position : relative;
}

.carousel {
  // Options
  $control-dim             : 50px;
  $slide-max-height        : 50vh;
  $caption-side-distance   : 0%;
  $caption-bottom-distance : 24px;
  overflow-x               : hidden;

  a.carousel-control-prev,
  a.carousel-control-next {
    top             : calc((50% - #{$control-dim/2} - var(--thumbnail-height, 0px) / 2));
    bottom          : auto;
    cursor          : pointer;
    text-decoration : none;
    line-height     : $control-dim;
    width           : $control-dim;
    height          : $control-dim;
    background      : transparent;
    transition      : 0.125s all ease-in-out;
    @if $rounded-buttons {
      border-radius : $control-dim/4;
    }
  }

  &.slide-in-buttons {
    @include media-breakpoint-up(md) {
      a.carousel-control-prev { transform : translateX(-$control-dim); }
      a.carousel-control-next { transform : translateX($control-dim); }
    }


    @include media-breakpoint-up(md) {
      &:hover {
        a.carousel-control-prev { transform : translateX($control-dim/4); }

        a.carousel-control-next { transform : translateX(-$control-dim/4); }
      }
    }
  }

  .carousel-indicators {
    &:not(.image-indicators) {
      margin-left     : 5%;
      margin-right    : 5%;
      justify-content : flex-end;

      li {
        border-radius : 100%;
        width         : 10px;
        height        : 10px;
      }
    }

    &.image-indicators {
      position        : relative;
      margin          : 0.25rem 0 0;
      justify-content : flex-start;
      overflow-x      : auto;

      li {
        width       : initial;
        height      : initial;
        text-indent : initial;
        padding     : 0;
        margin      : 0;
        border      : 0;

        & + li {
          margin-left : 0.25rem;
        }
      }

      @include media-breakpoint-down(xs) {
        img {
          max-width : 100px;
        }
      }
    }
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    &.image-top {
      @include flex("center");
    }

    &.image-center {
      @include flex("center");
    }

    &.image-bottom {
      @include flex("flex-end", "center");
    }
  }

  .carousel-item-overlay {
    @include set-position("full");
  }

  .carousel-image {
    max-width : 100%;
    height    : auto;
  }

  .carousel-caption {
    left    : $caption-side-distance;
    right   : $caption-side-distance;
    display : flex;
    padding : 0;

    .caption-body {
      --bs-bg-opacity : 0.75;
    }
  }

  // Carousel caption vertical alignment
  .caption-top-left,
  .caption-top-center,
  .caption-top-right {
    .carousel-caption {
      bottom      : auto;
      top         : 0; // $grid-gutter-width/2;
      align-items : flex-start;
    }
  }

  .caption-center-left,
  .caption-center,
  .caption-center-right {
    .carousel-caption {
      top         : 0;
      bottom      : 0;
      align-items : center;
    }
  }

  .caption-bottom-left,
  .caption-bottom-center,
  .caption-bottom-right {
    .carousel-caption {
      align-items : flex-end;
      bottom      : 0;
    }
  }

  // Carousel caption horizontal alignment
  .caption-bottom-left,
  .caption-center-left,
  .caption-top-left {
    .caption-body {
      margin-right    : auto;
      justify-content : flex-start;
    }
  }

  .caption-bottom-center,
  .caption-center,
  .caption-top-center {
    .caption-body {
      margin          : 0 auto;
      justify-content : center;
    }
  }

  .caption-bottom-right,
  .caption-center-right,
  .caption-top-right {
    .caption-body {
      margin-left     : auto;
      justify-content : flex-end;
    }
  }
}

.carousel-fullscreen {
  picture {
    display : flex;
    width   : 100vw;
    height  : 100vh;

    & > img {
      width           : 100%;
      object-fit      : cover;
      object-position : $fullscreen-position-x $fullscreen-position-y;
    }
  }
}

.modal {
  .carousel {
    .carousel-image {
      max-height : 75vh;

      @include media-breakpoint-up(md) {
        max-width : 75vw;
      }
    }
  }
}

/*-------------------------------------------*\
|* Layer carousel component                  *|
\*-------------------------------------------*/
.carousel-layer {
  .image-layer {
    @include flex("center", "flex-end");
    @include set-position("full");
    margin : 10% 10% 10% 40%;

    @include media-breakpoint-up(sm) {
      margin : 5% 20% 5% 50%;
    }

    @include media-breakpoint-up(md) {
      margin : 0 5% 5% 25%;
    }

    &.resized {
      @include media-breakpoint-up(md) {
        margin : 0 10% 5% 40%;
      }
    }

    &.single-image {
      @include media-breakpoint-up(md) {
        margin : 0 15% 0 45%;
      }
    }

    figure {
      &::before,
      &::after {
        z-index    : -1;
        position   : absolute;
        content    : "";
        bottom     : 8px;
        left       : 10px;
        width      : 50%;
        top        : 80%;
        max-width  : 300px;
        background : rgba(0, 0, 0, 0.75);
        box-shadow : 0 15px 10px rgba(0, 0, 0, 0.75);
        transform  : rotate(-3deg);
      }

      &::after {
        transform : rotate(3deg);
        right     : 10px;
        left      : auto;
      }

      &:first-child {
        order : 2;
      }

      &:nth-child(2) {
        order : 1;
      }

      &:nth-child(3) {
        order : 3;
      }

      &:not(:first-child) {
        display : none;

        @include media-breakpoint-up(md) {
          display : block;
        }
      }

      .image-pin {
        position  : absolute;
        top       : $grid-gutter-width/3;
        left      : 50%;
        transform : translateX(-50%);
        content   : '';
        width     : 42px;
        height    : 42px;

        img {
          width : auto;
        }
      }
    }

    img {
      display    : block;
      max-width  : 600px;
      width      : 100%;
      height     : auto;
      max-height : 100%;
    }

    .layer-1 {
      transform : rotate(3deg);
      z-index   : 100;

      @include media-breakpoint-up(md) {
        transform : rotate(3deg) scale(1.15);
      }
    }

    .layer-2 {
      transform : rotate(-1deg) translate(-5%, 45%);
      z-index   : 200;
    }

    .layer-3 {
      transform : rotate(3deg) translateY(35%);
      z-index   : 300;
    }

  }
}
