@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@100;300;400;700;900&display=swap");
@font-face {
  font-family: "Avenir LT W01 35 Light";
  src: url("../fonts/Avenir/Fonts/1475496/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475496/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 35 Light Oblique";
  src: url("../fonts/Avenir/Fonts/1475502/a59168c1-917d-4de9-a244-0316c057c357.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475502/6dc0e7d8-9284-44e1-8f05-984a41daa3a4.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 45 Book";
  src: url("../fonts/Avenir/Fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 45 Book Oblique";
  src: url("../fonts/Avenir/Fonts/1475514/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475514/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 55 Roman";
  src: url("../fonts/Avenir/Fonts/1475520/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475520/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 55 Oblique";
  src: url("../fonts/Avenir/Fonts/1475526/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475526/cdda031e-26e9-4269-83d1-5a218caa10db.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 65 Medium";
  src: url("../fonts/Avenir/Fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 65 Medium Oblique";
  src: url("../fonts/Avenir/Fonts/1475538/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475538/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 85 Heavy";
  src: url("../fonts/Avenir/Fonts/1475550/3c210c80-960f-4684-850b-25390b4d08af.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475550/cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 95 Black";
  src: url("../fonts/Avenir/Fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01 95 Black Olique";
  src: url("../fonts/Avenir/Fonts/1475562/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"), url("../fonts/Avenir/Fonts/1475562/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W05 85 Heavy";
  src: url("../fonts/Avenir/Fonts/5687390/0d51f252-d47b-421f-8ffe-eb417dce1b53.woff2") format("woff2"), url("../fonts/Avenir/Fonts/5687390/b34d406c-b12e-4b82-892e-1bfab6517cce.woff") format("woff");
}
/*-------------------------------------------*\
|* Components and page style sheets          *|
\*-------------------------------------------*/
:root {
  --swiper-theme-color: #8db533;
  --bs-body-font-family: "Avenir LT W01 55 Roman", Nunito Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1.15rem;
}

/*
 *   SASS type checking
 */
/*
 use this mixin carefully - combinations like "hover: hover && pointer: coarse" are possible
 your styles should result in a useable application - even if neither MOBILE nor DESKTOP matches
 */
/*
 use this mixin carefully - combinations like "hover: hover && pointer: coarse" are possible
 your styles should result in a useable application - even if neither MOBILE nor DESKTOP matches
 */
/*
 * CSS icons
 */
/** Typography utilities */
/** Shortcuts */
/** Flex utilities */
/** Position utilities */
/** Effects */
/*
 * Global utility styles
 */
.bg-tertary {
  background-color: #f9f9f9;
}

.btn-circle {
  border-radius: 50% !important;
}

.btn-animated {
  display: block;
  font-size: 1.5rem;
  padding: 0;
}
.btn-animated .btn-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  padding: 1rem;
  pointer-events: none;
  z-index: 900;
}
.btn-animated::before, .btn-animated::after {
  content: "";
  width: 0;
  transition: width 0.1s ease-in-out;
  z-index: 800;
}
.btn-animated.left::before {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
}
.btn-animated.top::before {
  position: absolute;
  bottom: auto;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 100%;
}
.btn-animated.right::after {
  position: absolute;
  bottom: 0;
  left: auto;
  top: 0;
  right: 0;
}
.btn-animated.bottom::after {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
  top: 100%;
}
@media screen and (hover: hover) {
  .btn-animated.left:hover::before {
    width: 100%;
  }
  .btn-animated.top:hover::before {
    bottom: 0;
  }
  .btn-animated.right:hover::after {
    width: 100%;
  }
  .btn-animated.bottom:hover::after {
    top: 0;
  }
}

.scroll-lock {
  overflow-y: hidden;
}

.responsive-h1 {
  font-weight: 900;
  font-size: calc(0.65 * 2.5rem + 0.5vw);
}

.responsive-h2 {
  font-size: calc(0.75 * 2rem + 0.5vw);
}

.responsive-h3 {
  font-size: calc(0.75 * 1.75rem + 0.5vw);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-around-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.set-top {
  position: absolute;
  bottom: auto;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
}

.set-right {
  position: absolute;
  bottom: 0;
  left: auto;
  top: 0;
  right: 0;
}

.set-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
}

.set-left {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
}

.set-full {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
}

.set-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-rotate-45 {
  transform: rotate(45deg);
}

.img-polaroid {
  border: 5px solid white;
  border-radius: 3px;
}
.img-polaroid + figcaption {
  background-color: white;
  min-height: 48px;
  margin-top: -3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.parallax {
  background: #575756 no-repeat 50% fixed;
  background-size: cover;
}

.zoom-image {
  overflow: hidden;
}
.zoom-image:hover img {
  transform: scale(1.05);
}
.zoom-image img {
  transition: all 0.25s ease-in-out;
}

[class^=col-count-],
[class*=" col-count-"] {
  column-gap: 0.5rem;
  column-width: 250px;
  column-count: var(--col-count, 1);
}
@media (min-width: 768px) {
  [class^=col-count-],
[class*=" col-count-"] {
    --col-count: 2;
  }
}

@media (min-width: 992px) {
  .col-count-3 {
    --col-count: 3;
  }

  .col-count-4 {
    --col-count: 4;
  }

  .col-count-5 {
    --col-count: 5;
  }

  .col-count-6 {
    --col-count: 6;
  }
}
.responsive-image {
  width: 100%;
  height: 100%;
}
@supports (aspect-ratio: 1) {
  .responsive-image > img, .responsive-image > iframe {
    display: block;
    aspect-ratio: var(--aspect-ratio, 1);
    margin: 0 auto;
  }
  @media screen and (min-width: 960px) {
    .responsive-image > img, .responsive-image > iframe {
      aspect-ratio: calc(var(--aspect-ratio, 1) / var(--desktop-adjustment, 1));
    }
  }
}
@supports not (aspect-ratio: 1) {
  .responsive-image {
    position: relative;
    padding-top: calc(100% / var(--aspect-ratio, 1));
  }
  @media (min-width: 992px) {
    .responsive-image {
      padding-top: calc(var(--desktop-adjustment) * 100% / var(--aspect-ratio, 1));
    }
  }
  .responsive-image > img, .responsive-image > iframe {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
  }
}

/* Typography */
h1, .h1 {
  font-family: "Avenir LT W01 95 Black", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2rem;
  }
}

h2, .h2 {
  font-family: "Avenir LT W01 95 Black", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 1.5rem;
}
h2 strong, .h2 strong {
  font-weight: 900;
}

h3, .h3 {
  font-family: "Avenir LT W01 65 Medium", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 1rem;
}
h3 strong, .h3 strong {
  font-weight: 900;
}

h5, .h5 {
  font-family: "Avenir LT W01 65 Medium", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h5.card-heading, .h5.card-heading {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h5.card-heading, .h5.card-heading {
    font-size: 1.5625rem;
  }
}

.btn-link {
  padding: 0;
}

/* Page Structure */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
}
body.loaded {
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0.5s, opacity 0.5s;
  transition: visibility 0.5s, opacity 0.5s;
}

header {
  -webkit-transition: box-shadow 0.2s, transform 0.2s;
  transition: box-shadow 0.2s, transform 0.2s;
}
header #main-header {
  -webkit-transition: padding 0.2s, height 0.2s;
  transition: padding 0.2s, height 0.2s;
}
@media (min-width: 992px) {
  header.is-sticky .main-nav .navbar-brand img {
    max-width: 7.125rem !important;
  }
}
@media (max-width: 991.98px) {
  header.is-sticky .navbar-toggler {
    padding: 0;
  }
}
header.hidden {
  transform: translateY(-100%);
}
header .main-nav {
  padding-top: 0;
  padding-bottom: 0;
}
header .main-nav .navbar-brand {
  min-width: 150px;
}
header .main-nav .navbar-brand img {
  -webkit-transition: max-width 0.2s, width 0.2s;
  transition: max-width 0.2s, width 0.2s;
}

header {
  max-width: 100vw;
  width: 100%;
  z-index: 1000;
}
header.header-sticky {
  position: fixed;
}
header.opaque {
  background-color: #fff;
}
header #main-topbar {
  --main-topbar-height: 2.5rem;
  overflow: hidden;
  -webkit-transition: margin 0.2s;
  transition: margin 0.2s;
}
header #main-topbar.hidden {
  margin-top: calc(-1 * var(--main-topbar-height));
}
header #main-topbar > .container, header #main-topbar > .container-fluid {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar > .container-sm {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar > .container-md {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar > .container-lg {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar > .container-xl {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar > .container-xxl {
  display: flex;
  align-items: center;
  gap: 1rem;
}
header #main-topbar .nav-link {
  padding: 0 0.5rem;
  color: #f8f9fa;
  border: none;
}
header #main-topbar .nav-link:hover, header #main-topbar .nav-link:focus, header #main-topbar .nav-link:active {
  color: #cbd3da;
}
header #main-topbar .langswitcher {
  --langswitcher-nav-link-color: #f8f9fa;
  --langswitcher-nav-link-hover-color: #cbd3da;
  --langswitcher-nav-link-border-color: #f8f9fa;
}
header .fixed-nav-social {
  position: absolute;
  bottom: 0;
  left: auto;
  top: 0;
  right: 0;
  top: 25vh;
  bottom: auto;
}
@media (min-width: 992px) {
  header .fixed-nav-social {
    position: fixed;
    top: 15%;
  }
}
header .fixed-nav-social .nav-link {
  font-size: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
header .main-nav {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
header .main-nav.invisible {
  visibility: hidden;
  opacity: 0;
}
header .main-nav .navbar-brand {
  min-width: 4.5rem;
}
@media (min-width: 992px) {
  header .main-nav .navbar-brand {
    min-width: 9.5rem;
  }
}
header .main-nav .navbar-brand img {
  flex: 1 0 0;
  max-width: 4.5rem;
}
@media (min-width: 992px) {
  header .main-nav .navbar-brand img {
    max-width: 9.5rem;
  }
}
header .main-nav .offcanvas-header {
  box-shadow: 0 0 3px 0 #405217;
}

#page-banner {
  margin-top: 4.5rem;
}
@media (min-width: 992px) {
  #page-banner {
    margin-top: 9.5rem;
  }
}
.is-sticky + #page-banner {
  margin-top: 7.125rem;
}

section.fullscreen {
  width: 100vw;
  height: 100vh;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
main > section {
  position: relative;
  background-size: cover;
  padding-bottom: 3rem;
}
@media (min-width: 992px) {
  main > section {
    padding-bottom: 6rem;
  }
}
main .main-content {
  position: relative;
  flex: 1 0 auto;
  padding: 3rem 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  main .main-content {
    padding: 6rem 0;
  }
}

footer {
  flex-shrink: 0;
}
footer .copyright {
  padding: 1rem 0;
  text-align: right;
  background-color: #3D3D3D;
  color: #ced4da;
}
footer .nav .nav-link {
  text-align: left;
}
@media (max-width: 767.98px) {
  footer .social.nav {
    justify-content: center;
  }
}
footer .social.nav .nav-link {
  color: white;
}
footer .social.nav .nav-link:hover, footer .social.nav .nav-link:focus, footer .social.nav .nav-link:active {
  color: #bfbfbf;
}
footer .social.nav .nav-item + .nav-item .nav-link {
  padding-left: 0;
}

#overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 99999px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 9000;
}
#overlay:not(.show) {
  height: 0;
  opacity: 0;
  pointer-events: none;
}

/* Flex Objects */
#flex-blog article + article,
#flex-contacts article + article {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #dee2e6;
}

.section-fade-in {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  background-color: white;
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  z-index: 1000;
}
.section-fade-in:not(.show) {
  top: -100px;
  height: 0;
  bottom: auto;
  pointer-events: none;
}
.section-fade-in.show {
  opacity: 1;
  height: 100%;
  pointer-events: initial;
}
.section-fade-in .section-header {
  display: flex;
  justify-content: flex-end;
}
.section-fade-in .section-header .close {
  float: none;
  padding: 8px 13px;
  margin: 0.5rem;
  color: white;
  background-color: #8db533;
  border-color: #8db533;
  opacity: 1;
}
.section-fade-in .section-header .close:hover, .section-fade-in .section-header .close:focus, .section-fade-in .section-header .close:active {
  color: white;
}

.social.nav .nav-item {
  padding: 0.375rem;
}

.text .article-image {
  display: none;
  border-left: 1px solid #8db533;
}
@media (min-width: 576px) {
  .text .article-image {
    display: flex;
    align-items: flex-start;
    flex-basis: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .text .article-image {
    border: 0;
    align-items: center;
  }
}

#grav-login .form-field {
  text-align: center;
}
#grav-login .form-field + .form-field {
  margin-top: 1rem;
}
#grav-login .form-label {
  display: none;
}
#grav-login .form-actions {
  margin-top: 1rem;
  text-align: center;
}

.bottom-mobile-menu .bottom-mobile-container {
  position: relative;
}
.bottom-mobile-menu .bottom-mobile-container .btn-group {
  position: static;
  flex: 1 1 auto;
}
.bottom-mobile-menu .bottom-mobile-container .btn-group > .btn {
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  color: #f8f9fa;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-container {
  padding: 0 1.5rem 1.5rem;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-container .dropdown-title {
  font-family: "Avenir LT W01 95 Black", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 1.5rem;
  padding-left: 1rem;
  border-left: 2px solid #8db533;
  white-space: nowrap;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .bottom-mobile-menu .bottom-mobile-container .dropdown-container .dropdown-title {
    font-size: 1.5rem;
  }
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-toggle::after {
  display: none;
  content: none;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-toggle.show {
  background-color: white;
  border-color: white;
  color: #8db533;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-toggle.show .icon-inactive {
  display: none;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-toggle.show .icon-active {
  display: block;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-toggle .icon-active {
  display: none;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-menu {
  width: 100%;
  left: 0 !important;
  max-height: calc(80vh - 54px);
  overflow: hidden auto;
  padding: 0;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-menu.menu {
  height: 100vh;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-menu.menu .dropdown-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 3rem 0;
  height: 100%;
}
.bottom-mobile-menu .bottom-mobile-container .dropdown-menu.menu .dropdown-menu {
  position: static !important;
  transform: unset !important;
}

.cards .row,
.gallery .row {
  width: 100%;
}
.cards .col:last-child .card,
.gallery .col:last-child .card {
  margin-bottom: 0;
}
.cards .card-group.body-overlay .card-body,
.gallery .card-group.body-overlay .card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
  padding: 0.75rem;
}

.card .card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
.card:hover .card-overlay, .card:focus .card-overlay, .card:active .card-overlay {
  background-color: transparent;
  visibility: visible;
  opacity: 0.25;
}
.card .card-content {
  position: relative;
}
.card .card-content.card-img-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel-container {
  position: relative;
}

.carousel {
  overflow-x: hidden;
}
.carousel a.carousel-control-prev,
.carousel a.carousel-control-next {
  top: calc(50% - 25px - var(--thumbnail-height, 0px) / 2);
  bottom: auto;
  cursor: pointer;
  text-decoration: none;
  line-height: 50px;
  width: 50px;
  height: 50px;
  background: transparent;
  transition: 0.125s all ease-in-out;
}
@media (min-width: 768px) {
  .carousel.slide-in-buttons a.carousel-control-prev {
    transform: translateX(-50px);
  }
  .carousel.slide-in-buttons a.carousel-control-next {
    transform: translateX(50px);
  }
}
@media (min-width: 768px) {
  .carousel.slide-in-buttons:hover a.carousel-control-prev {
    transform: translateX(12.5px);
  }
  .carousel.slide-in-buttons:hover a.carousel-control-next {
    transform: translateX(-12.5px);
  }
}
.carousel .carousel-indicators:not(.image-indicators) {
  margin-left: 5%;
  margin-right: 5%;
  justify-content: flex-end;
}
.carousel .carousel-indicators:not(.image-indicators) li {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.carousel .carousel-indicators.image-indicators {
  position: relative;
  margin: 0.25rem 0 0;
  justify-content: flex-start;
  overflow-x: auto;
}
.carousel .carousel-indicators.image-indicators li {
  width: initial;
  height: initial;
  text-indent: initial;
  padding: 0;
  margin: 0;
  border: 0;
}
.carousel .carousel-indicators.image-indicators li + li {
  margin-left: 0.25rem;
}
.carousel .carousel-indicators.image-indicators img {
  max-width: 100px;
}
.carousel .carousel-item.active.image-top,
.carousel .carousel-item-next.image-top,
.carousel .carousel-item-prev.image-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .carousel-item.active.image-center,
.carousel .carousel-item-next.image-center,
.carousel .carousel-item-prev.image-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .carousel-item.active.image-bottom,
.carousel .carousel-item-next.image-bottom,
.carousel .carousel-item-prev.image-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.carousel .carousel-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
}
.carousel .carousel-image {
  max-width: 100%;
  height: auto;
}
.carousel .carousel-caption {
  left: 0%;
  right: 0%;
  display: flex;
  padding: 0;
}
.carousel .carousel-caption .caption-body {
  --bs-bg-opacity: 0.75;
}
.carousel .caption-top-left .carousel-caption,
.carousel .caption-top-center .carousel-caption,
.carousel .caption-top-right .carousel-caption {
  bottom: auto;
  top: 0;
  align-items: flex-start;
}
.carousel .caption-center-left .carousel-caption,
.carousel .caption-center .carousel-caption,
.carousel .caption-center-right .carousel-caption {
  top: 0;
  bottom: 0;
  align-items: center;
}
.carousel .caption-bottom-left .carousel-caption,
.carousel .caption-bottom-center .carousel-caption,
.carousel .caption-bottom-right .carousel-caption {
  align-items: flex-end;
  bottom: 0;
}
.carousel .caption-bottom-left .caption-body,
.carousel .caption-center-left .caption-body,
.carousel .caption-top-left .caption-body {
  margin-right: auto;
  justify-content: flex-start;
}
.carousel .caption-bottom-center .caption-body,
.carousel .caption-center .caption-body,
.carousel .caption-top-center .caption-body {
  margin: 0 auto;
  justify-content: center;
}
.carousel .caption-bottom-right .caption-body,
.carousel .caption-center-right .caption-body,
.carousel .caption-top-right .caption-body {
  margin-left: auto;
  justify-content: flex-end;
}

.carousel-fullscreen picture {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.carousel-fullscreen picture > img {
  width: 100%;
  object-fit: cover;
  object-position: 75% 50%;
}

.modal .carousel .carousel-image {
  max-height: 75vh;
}
@media (min-width: 768px) {
  .modal .carousel .carousel-image {
    max-width: 75vw;
  }
}

/*-------------------------------------------*\
|* Layer carousel component                  *|
\*-------------------------------------------*/
.carousel-layer .image-layer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  margin: 10% 10% 10% 40%;
}
@media (min-width: 576px) {
  .carousel-layer .image-layer {
    margin: 5% 20% 5% 50%;
  }
}
@media (min-width: 768px) {
  .carousel-layer .image-layer {
    margin: 0 5% 5% 25%;
  }
}
@media (min-width: 768px) {
  .carousel-layer .image-layer.resized {
    margin: 0 10% 5% 40%;
  }
}
@media (min-width: 768px) {
  .carousel-layer .image-layer.single-image {
    margin: 0 15% 0 45%;
  }
}
.carousel-layer .image-layer figure::before, .carousel-layer .image-layer figure::after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 8px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.75);
  transform: rotate(-3deg);
}
.carousel-layer .image-layer figure::after {
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.carousel-layer .image-layer figure:first-child {
  order: 2;
}
.carousel-layer .image-layer figure:nth-child(2) {
  order: 1;
}
.carousel-layer .image-layer figure:nth-child(3) {
  order: 3;
}
.carousel-layer .image-layer figure:not(:first-child) {
  display: none;
}
@media (min-width: 768px) {
  .carousel-layer .image-layer figure:not(:first-child) {
    display: block;
  }
}
.carousel-layer .image-layer figure .image-pin {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 42px;
  height: 42px;
}
.carousel-layer .image-layer figure .image-pin img {
  width: auto;
}
.carousel-layer .image-layer img {
  display: block;
  max-width: 600px;
  width: 100%;
  height: auto;
  max-height: 100%;
}
.carousel-layer .image-layer .layer-1 {
  transform: rotate(3deg);
  z-index: 100;
}
@media (min-width: 768px) {
  .carousel-layer .image-layer .layer-1 {
    transform: rotate(3deg) scale(1.15);
  }
}
.carousel-layer .image-layer .layer-2 {
  transform: rotate(-1deg) translate(-5%, 45%);
  z-index: 200;
}
.carousel-layer .image-layer .layer-3 {
  transform: rotate(3deg) translateY(35%);
  z-index: 300;
}

.content-grid .cell {
  position: relative;
}
.content-grid .cell-image {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}
.content-grid .cell-image img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.content-grid.banner .cell {
  --cell-padding: 0;
  padding: var(--cell-padding);
}
@media (min-width: 768px) {
  .content-grid.banner .cell:first-child {
    padding-right: var(--cell-padding-right);
  }
  .content-grid.banner .cell:first-child .cell-content {
    right: 0;
    padding-left: 3rem;
  }
  .content-grid.banner .cell:not(:first-child) {
    padding-left: var(--cell-padding-left);
  }
}
.content-grid.banner .cell + .cell {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .content-grid.banner .cell + .cell {
    margin-top: 0;
  }
}
.content-grid.banner .cell-content {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  background-color: rgba(56, 179, 223, 0.75);
  padding: 1.5rem;
  margin-right: var(--cell-padding-right, 0);
  width: 100%;
}
@media (min-width: 768px) {
  .content-grid.banner .cell-content {
    min-height: 50%;
  }
}
@media (min-width: 992px) {
  .content-grid.banner .cell-content {
    width: auto;
    min-height: 65%;
    padding: 2rem 3rem;
  }
}

.features {
  position: relative;
  margin-top: 0;
  padding-top: 5px;
}
.features.slide-down .card-header {
  top: 0;
}
.features.slide-down .card-footer {
  display: none;
}
.features.slide-down .card:hover .card-header, .features.slide-down .card:focus .card-header, .features.slide-down .card:active .card-header {
  top: 50%;
}
.features.slide-in-out .card-header {
  left: 0;
}
.features.slide-in-out .card-footer {
  left: 200%;
}
.features.slide-in-out .card:hover .card-header, .features.slide-in-out .card:focus .card-header, .features.slide-in-out .card:active .card-header {
  left: 200%;
}
.features.slide-in-out .card:hover .card-footer, .features.slide-in-out .card:focus .card-footer, .features.slide-in-out .card:active .card-footer {
  left: 0;
}
.features .card {
  background: transparent;
  border: 0;
  overflow: hidden;
}
.features .card:hover img, .features .card:focus img, .features .card:active img {
  transform: scale(1.25);
  transition: transform 2.5s ease-in-out;
}
.features .card .card-img {
  filter: grayscale(100%);
}
.features .card:hover .card-img, .features .card:focus .card-img, .features .card:active .card-img {
  filter: grayscale(0%);
}
.features .card:hover .card-header,
.features .card:hover .card-footer, .features .card:focus .card-header,
.features .card:focus .card-footer, .features .card:active .card-header,
.features .card:active .card-footer {
  color: white;
}
.features .card-content {
  flex-direction: column;
}
.features .card-header,
.features .card-footer {
  position: relative;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  text-align: center;
  text-shadow: -1px -1px 1px #333;
  border: 0;
  -webkit-transition: top 0.2s, left 0.2s, color 0.2s;
  transition: top 0.2s, left 0.2s, color 0.2s;
}
.features .card-header h5,
.features .card-footer h5 {
  font-family: "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2rem;
  font-weight: bolder;
}
.features .card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
  border-top: 0;
  background: none;
  left: 200%;
  font-size: 1.75rem;
  font-weight: bolder;
}
.features .card-overlay {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 0) 50%);
}

.floating-action-menu {
  --fab-menu-width: 4rem;
  --fab-menu-offset-top: 26vh;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
  top: 25%;
  bottom: 25%;
  z-index: 9500;
}
.floating-action-menu .floating-action-container {
  position: relative;
}
.floating-action-menu .floating-action-container .btn-group {
  position: static;
}
.floating-action-menu .floating-action-container .btn-group > .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  color: #f8f9fa;
}
.floating-action-menu .floating-action-container .dropdown-container {
  padding: 0 3rem 3rem;
}
.floating-action-menu .floating-action-container .dropdown-container .dropdown-title {
  font-family: "Avenir LT W01 95 Black", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 1.5rem;
  padding-left: 1rem;
  border-left: 2px solid #8db533;
  white-space: nowrap;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .floating-action-menu .floating-action-container .dropdown-container .dropdown-title {
    font-size: 1.5rem;
  }
}
.floating-action-menu .floating-action-container .dropdown-toggle::after {
  display: none;
  content: none;
}
.floating-action-menu .floating-action-container .dropdown-toggle.show {
  background-color: white;
  border-color: white;
  color: #8db533;
}
.floating-action-menu .floating-action-container .dropdown-toggle.show .icon-inactive {
  display: none;
}
.floating-action-menu .floating-action-container .dropdown-toggle.show .icon-active {
  display: block;
}
.floating-action-menu .floating-action-container .dropdown-toggle .icon-active {
  display: none;
}
.floating-action-menu .floating-action-container i {
  pointer-events: none;
}
.floating-action-menu .floating-action-container .dropdown-menu {
  max-height: calc(100vh - var(--fab-menu-offset-top));
  width: max-content;
  max-width: calc(100vw - var(--fab-menu-width));
  overflow-y: auto;
  transform: translate(3.5rem, 0) !important;
  padding: 0;
}
@media (min-width: 768px) {
  .floating-action-menu .floating-action-container .dropdown-menu.page, .floating-action-menu .floating-action-container .dropdown-menu.form {
    max-width: calc(100vw - 2 * var(--fab-menu-width));
  }
}
@media (min-width: 992px) {
  .floating-action-menu .floating-action-container .dropdown-menu.form {
    max-width: calc(960px);
  }
}

/*
 *  General form styles
 */
.form-section .page-content,
.form-section .form-container {
  margin: 3rem auto 0;
}
@media (min-width: 992px) {
  .form-section .page-content,
.form-section .form-container {
    max-width: 75%;
  }
}

form .btn {
  min-width: 100px;
}
form.form-theme .form-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}
form.form-theme .form-checkboxes .label {
  font-size: 1.25rem;
  font-weight: bold;
}
form.form-theme .form-checkboxes .checkboxes input[type=checkbox]:checked + label:before {
  line-height: inherit;
  font-size: inherit;
}
form.form-theme .form-checkboxes .checkboxes label {
  display: inline-flex !important;
  align-items: center;
  padding-left: 2.25rem;
}
form.form-theme .form-checkboxes .checkboxes label::before {
  width: 1.5em;
  height: 1.5em;
  background-color: rgba(141, 181, 51, 0.25);
  border: 1px solid rgba(141, 181, 51, 0.5);
  border-radius: 0;
}
form.form-theme .hide-title h3 {
  visibility: hidden;
}
@media (max-width: 991.98px) {
  form.form-theme .hide-title h3 {
    margin: 0;
    padding: 0;
    height: 0;
  }
}

.form-field {
  margin-bottom: 1rem;
}
.form-field:not(.form-floating) .label {
  margin-bottom: 0.25rem;
  color: #6c757d;
}
.form-field.form-floating [type=text]::placeholder, .form-field.form-floating textarea::placeholder, .form-field.form-floating select::placeholder {
  color: transparent !important;
}

.form-control.long {
  min-height: 190px;
}
.form-control.large {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.form-control [type=text], .form-control textarea, .form-control select {
  border: none;
  border-bottom: 2px solid rgba(73, 80, 87, 0.25);
}
.form-control [type=text]:focus, .form-control [type=text]:not(:placeholder-shown), .form-control textarea:focus, .form-control textarea:not(:placeholder-shown), .form-control select:focus, .form-control select:not(:placeholder-shown) {
  border-color: rgba(141, 181, 51, 0.5);
}

.form-checkbox-field {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.form-checkbox-field .form-extra-wrapper {
  padding-left: 0;
}

.form-check {
  display: inline-block;
}
.form-check label {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* Change Autocomplete styles in Chrome*/
.button-group {
  display: flex;
}
.button-group button {
  display: block;
  width: 100%;
  flex-basis: 50%;
  height: calc(2.25rem + 1.5rem / 2 + 2px);
}
.button-group button + button {
  margin-left: 10px;
}

.langswitcher {
  --langswitcher-nav-link-color: #8db533;
  --langswitcher-nav-link-hover-color: #719129;
  --langswitcher-nav-link-border-color: #8db533;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.langswitcher .langswitcher-item + .langswitcher-item::before {
  content: "";
  border-left: 1px solid var(--langswitcher-nav-link-border-color);
  margin-right: 0.5rem;
}
.langswitcher a {
  text-decoration: none;
  color: var(--langswitcher-nav-link-color);
}
.langswitcher a:hover, .langswitcher a:focus, .langswitcher a:active {
  color: var(--langswitcher-nav-link-hover-color);
}
.langswitcher a.active {
  text-decoration: underline;
}

.modal .modal-header .container-fluid {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  flex-shrink: inherit;
}
.modal .modal-header .btn-close:focus {
  box-shadow: none;
}
@media (min-width: 576px) {
  .modal .modal-full {
    margin: 0 auto;
    max-width: 1500px;
  }
}
.modal .modal-full .modal-content {
  border: none;
}
.modal .modal-full .modal-body {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.modal-dark .modal-content,
.modal-black .modal-content {
  color: #f8f9fa;
}

@media (max-width: 991.98px) {
  .side-nav {
    display: none !important;
  }
}

.navbar-brand figure {
  margin-bottom: 0;
}

.nav-item {
  position: relative;
}

.nav-link {
  text-align: center;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  border: 0;
  border-radius: 0;
}
@media (max-width: 991.98px) {
  .dropdown-menu .dropdown-item {
    text-align: center;
  }
  .dropdown-menu .dropdown-item::before, .dropdown-menu .dropdown-item::after {
    display: inline-block;
    content: "-";
  }
}
@media (min-width: 992px) {
  .dropdown-menu .dropdown-item {
    text-align: initial;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-fluid {
    flex-direction: column;
  }

  .navbar-toggler {
    padding: 1.5rem 0;
    border: 0;
  }
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-toggler.collapsed .icon-bars::before, .navbar-toggler.collapsed .icon-bars::after, .navbar-toggler.collapsed .icon-bars .icon-bar {
    transform: none;
    opacity: 1;
  }
  .navbar-toggler .icon-bars {
    display: flex;
    flex-direction: column;
    width: 24px;
    align-items: flex-end;
    pointer-events: none;
  }
  .navbar-toggler .icon-bars::before, .navbar-toggler .icon-bars::after,
.navbar-toggler .icon-bars .icon-bar {
    display: block;
    width: 100%;
    margin: 2px 0;
    content: "";
    background-color: #8db533;
    border: 1px solid #8db533;
    transition: all 0.25s ease-in-out;
  }
  .navbar-toggler .icon-bars .icon-bar {
    opacity: 0;
  }
  .navbar-toggler .icon-bars::before {
    transform: rotate(45deg);
    transform-origin: 25% 0;
  }
  .navbar-toggler .icon-bars::after {
    transform: rotate(-45deg);
    transform-origin: 5% 0;
  }
}
/*
 * Different slide animation types for the bootstrap mobile navigation
 * Add one of these classes to the navbar classes in navigation.html.twig:
 * nav-slide-down, nav-slide-left, nav-slide-right
 */
@media (max-width: 991.98px) {
  [class*=nav-slide-] .navbar-brand,
[class*=nav-slide-] .navbar-toggler {
    z-index: 1000;
  }
  [class*=nav-slide-] .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    padding: 0.75rem;
    padding-top: 5.25rem;
    background-color: white;
    z-index: 900;
  }

  .nav-slide-down .navbar-collapse {
    width: 90%;
    left: 5%;
    right: 5%;
  }

  .nav-slide-right .collapse:not(.show),
.nav-slide-left .collapse:not(.show) {
    display: block;
  }
  .nav-slide-right .navbar-collapse,
.nav-slide-left .navbar-collapse {
    height: 100% !important;
  }
  .nav-slide-right .collapsed + .navbar-collapse.collapsing,
.nav-slide-left .collapsed + .navbar-collapse.collapsing {
    overflow: visible;
  }

  .nav-slide-right .navbar-collapse {
    left: unset;
    right: 0;
    transition: right 0.5s ease-in-out;
  }
  .nav-slide-right .collapsed + .navbar-collapse {
    right: -100vw;
  }

  .nav-slide-left .navbar-collapse {
    left: 0;
    right: unset;
    transition: left 0.5s ease-in-out;
  }
  .nav-slide-left .collapsed + .navbar-collapse {
    left: -100vw;
  }
}
.offcanvas .offcanvas-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
header.is-sticky .offcanvas .offcanvas-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
header.is-sticky .offcanvas .offcanvas-header .logo-image {
  max-width: 3.15rem !important;
}
.offcanvas .offcanvas-body::-webkit-scrollbar {
  width: 0.5rem;
}
.offcanvas .offcanvas-body::-webkit-scrollbar-thumb {
  background: #5f7922;
  border-radius: 1.75rem;
}
.offcanvas .offcanvas-body::-webkit-scrollbar-track {
  background: #7d7d7c;
}

@media (min-width: 992px) {
  .navbar-expand-lg .offcanvas {
    --bs-bg-opacity: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    justify-content: center;
  }
}
.scrolldown {
  --scrolldown-color: #707070;
  --scrolldown-hover-color: var(--scrolldown-color);
  --scrolldown-bg: #8db533;
  --scrolldown-hover-bg: #5f7922;
  --scrolldown-border-color: transparent;
  --scrolldown-border-hover-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0;
  color: var(--scrolldown-color);
  z-index: 900;
}
.scrolldown.scrolldown-light {
  --scrolldown-bg: #f8f9fa;
  --scrolldown-hover-bg: #e9ecef;
  --scrolldown-color: #575756;
}
.scrolldown.scrolldown-dark {
  --scrolldown-bg: #575756;
  --scrolldown-hover-bg: #4a4a49;
  --scrolldown-color: #f8f9fa;
}
.scrolldown.scrolldown-outline {
  --scrolldown-bg: transparent;
  --scrolldown-hover-bg: var(--scrolldown-border-color);
  --scrolldown-border-color: var(--scrolldown-color);
}
.scrolldown.scrolldown-outline.scrolldown-light {
  --scrolldown-color: #f8f9fa;
  --scrolldown-hover-color: #575756;
}
.scrolldown.scrolldown-outline.scrolldown-dark {
  --scrolldown-color: #575756;
  --scrolldown-hover-color: #f8f9fa;
}
.scrolldown a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--scrolldown-bg);
  border: 2px solid var(--scrolldown-border-color);
  color: var(--scrolldown-color);
  text-decoration: none;
}
.scrolldown a:hover, .scrolldown a:focus, .scrolldown a:active {
  color: var(--scrolldown-hover-color);
  background-color: var(--scrolldown-hover-bg);
  border-color: var(--scrolldown-border-hover-color);
}
.scrolldown .scrolldown-text {
  display: none;
  margin-top: 1rem;
  text-shadow: 1px 1px 2px black;
}
@media (min-width: 768px) {
  .scrolldown .scrolldown-text {
    display: block;
  }
}

.skill-sets .skill-set dl {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .skill-sets .skill-set dt, .skill-sets .skill-set dd {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
  }
}
.skill-sets .set-title::after {
  display: block;
  width: 50%;
  content: "";
  border-bottom: 1px solid #8db533;
}
.skill-sets .progress {
  margin: 6px 0;
}
.skill-sets .progress .progress-bar {
  text-align: left;
  padding: 0 0.375rem;
  transition: width 1s ease;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.15;
}

.swiper-slide.card {
  border: none;
}

.back-to-top {
  position: fixed;
  bottom: 6rem;
  right: 1.5rem;
  transition: all 0.5s linear;
  z-index: 1020;
}
@media (min-width: 768px) {
  .back-to-top {
    bottom: 3rem;
  }
}
.back-to-top[data-mode=animated] {
  opacity: 0;
}
.back-to-top[data-mode=animated].hidden {
  display: none;
}
.back-to-top[data-mode=animated].show {
  opacity: 1;
}
.back-to-top .container {
  position: relative;
}
.back-to-top a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: auto;
  transform: translate(-50%, 50%);
  border: none;
  width: 50px;
  height: 50px;
  color: white;
  background-color: #8db533;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.25s linear;
}
.back-to-top a.btn:hover {
  opacity: 1;
}
.back-to-top a.btn-top {
  border: 1px solid rgba(141, 181, 51, 0.9);
  color: rgba(141, 181, 51, 0.9);
}
.back-to-top a.btn-bottom {
  border: 1px solid rgba(141, 181, 51, 0.9);
  color: rgba(141, 181, 51, 0.9);
}
@media (min-width: 768px) {
  .back-to-top a.btn-bottom {
    border: 1px solid rgba(255, 255, 255, 0.9);
    color: rgba(255, 255, 255, 0.9);
  }
}

.portfolio-container .card .card-content {
  padding: 0;
}
.portfolio-container .card .card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  color: #ffc107;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px #3b3535;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
.portfolio-container .card:hover .card-body, .portfolio-container .card:focus .card-body, .portfolio-container .card:active .card-body {
  visibility: visible;
  opacity: 1;
}

.waiting,
.animate__animated:not(.show) {
  opacity: 0 !important;
}

body {
  margin-bottom: 3.25rem;
}
@media (min-width: 992px) {
  body {
    margin-bottom: 0;
  }
}

.module-title {
  position: relative;
  display: flex;
  font-weight: 900;
  margin-bottom: 3rem;
  padding-top: 3rem;
  padding-left: 1.5rem;
}
@media (min-width: 992px) {
  .module-title {
    padding-top: 6rem;
  }
}
.module-title::before {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
  display: block;
  content: "";
  border-left: 3px solid #8db533;
}

#main-topbar {
  display: none;
}
@media (min-width: 992px) {
  #main-topbar {
    display: block;
  }
}
#main-topbar .topbar-nav {
  margin-left: auto;
}
#main-topbar .topbar-nav .nav-item {
  display: inline-flex;
}
#main-topbar .topbar-nav .nav-item + .nav-item::before {
  content: "";
  border-left: 1px solid #f8f9fa;
  margin-top: 0.14375rem;
  margin-bottom: 0.14375rem;
}

@media (min-width: 992px) {
  #main-nav > .container, #main-nav > .container-fluid {
    align-items: flex-end;
  }
  #main-nav > .container-sm {
    align-items: flex-end;
  }
  #main-nav > .container-md {
    align-items: flex-end;
  }
  #main-nav > .container-lg {
    align-items: flex-end;
  }
  #main-nav > .container-xl {
    align-items: flex-end;
  }
  #main-nav > .container-xxl {
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  #main-nav .langswitcher,
#main-nav .navbar-collapse {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) {
  #main-nav .langswitcher {
    display: none;
  }
}
#main-nav .navbar-module {
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  color: #71716f;
  padding-left: 1.5rem;
  margin-bottom: 2rem;
}
#main-nav .navbar-module::before {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
  display: block;
  content: "";
  border-left: 3px solid #8db533;
}
#main-nav .navbar-module .navbar-module-label {
  text-transform: uppercase;
  font-size: 80%;
}
#main-nav .navbar-module .phone-link {
  text-decoration: none;
  font-weight: bold;
  color: #71716f;
  letter-spacing: 1px;
}
#main-nav .navbar-module .phone-link:hover, #main-nav .navbar-module .phone-link:focus, #main-nav .navbar-module .phone-link:active {
  text-decoration: underline;
}
#main-nav .navbar-nav {
  margin-left: 15%;
  flex: 1 0 auto;
}
#main-nav .nav-link.active::after {
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  content: "";
  width: 1.25rem;
  aspect-ratio: 1;
  background: url("../../images/gml-blatt.png") no-repeat center center;
  background-size: contain;
}
#main-nav .login {
  display: none;
}

.contact-block {
  border-left: 3px solid #8db533;
  margin: 0 0 3rem;
}
.contact-block .phone-link {
  color: inherit;
  text-decoration: none;
}
.contact-block .phone-link:hover, .contact-block .phone-link:focus, .contact-block .phone-link:active {
  text-decoration: underline;
}

.footer-columns .nav-link {
  padding: 0.25rem 1rem 0.25rem 0;
  color: #f8f9fa;
}
.footer-columns .nav-link:hover, .footer-columns .nav-link:focus, .footer-columns .nav-link:active {
  color: #adb5bd;
}
.footer-columns .footer-contact p {
  line-height: 2;
  margin-bottom: 2rem;
}

#bottom-mobile-nav.flex-column {
  width: 80%;
  height: 80%;
  justify-content: space-around;
  align-items: flex-end;
}
#bottom-mobile-nav .nav-link {
  font-size: 150%;
  color: #707070;
}

/**
 * GML-specific styles
*/
#page-banner,
.gml-banner {
  padding-bottom: 0;
}
@media (max-width: 767.98px) {
  #page-banner .caption-top-right .carousel-caption,
.gml-banner .caption-top-right .carousel-caption {
    top: auto;
    bottom: 0;
  }
}
#page-banner .caption-body,
.gml-banner .caption-body {
  background-color: rgba(56, 179, 223, 0.75);
  padding: 2rem;
  text-align: right;
}
@media (min-width: 992px) {
  #page-banner .caption-body,
.gml-banner .caption-body {
    min-width: 25%;
  }
}
#page-banner .caption-body h3,
.gml-banner .caption-body h3 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  #page-banner .caption-body h3,
.gml-banner .caption-body h3 {
    font-size: 2.5rem;
  }
}
#page-banner .caption-body h3:only-child,
.gml-banner .caption-body h3:only-child {
  margin-bottom: 0;
}

.gml-features {
  --swiper-navigation-size: 1.5rem;
}
.gml-features .card .card-header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  color: white;
  background-color: rgba(56, 179, 223, 0.75);
  padding: 1.5rem;
  transform: translateY(-100%);
}
@media (min-width: 768px) {
  .gml-features .card .card-header {
    width: auto;
  }
}
@media (min-width: 768px) {
  .gml-features .card .card-header-appendix {
    display: none;
  }
}
.gml-features .card .card-body {
  padding-left: 0;
  padding-right: 0;
}
.gml-features .carousel-container {
  background-color: #8DB5331A;
  padding-top: calc(var(--bs-gutter-x) * 1);
  padding-bottom: calc(var(--bs-gutter-x) * 1);
}
@media (min-width: 768px) {
  .gml-features .carousel-container {
    background-color: transparent;
  }
}
.gml-features .swiper-button-prev,
.gml-features .swiper-button-next {
  width: 3.5rem;
  height: 3.5rem;
  color: white;
  background-color: #8db533;
  top: 25%;
}
.gml-features .swiper-button-prev.swiper-button-disabled,
.gml-features .swiper-button-next.swiper-button-disabled {
  opacity: 0.25;
}
.gml-features .swiper-button-prev {
  left: 0;
}
.gml-features .swiper-button-next {
  right: 0;
}
.gml-features .swiper-pagination {
  bottom: -0.25rem;
}

#gml-technology {
  padding-top: 3rem;
}

#services-cta .services-cta-btn {
  padding: 1rem 1.5rem;
}

.home .banner {
  position: relative;
}
.home .banner .intro-text {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  right: 0;
  width: 100%;
  display: none;
  z-index: 100;
}
@media (min-width: 992px) {
  .home .banner .intro-text {
    display: block;
  }
}
.home .banner .intro-text .row {
  color: white;
  background-color: rgba(56, 179, 223, 0.75);
}
.home .banner .intro-text .col {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 3rem;
  text-align: right;
}
.home .banner .intro-text .col:first-child {
  padding-right: 8.5rem;
}
.home .banner .intro-text .col:last-child {
  padding-left: 0;
  text-align: left;
}
@media (min-width: 768px) {
  .home .banner .cell:last-child::before {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: auto;
    left: -0.5rem;
    content: "";
    padding: 0.5rem;
    background-color: var(--bs-body-bg);
    z-index: 150;
  }
}
@media (min-width: 992px) {
  .home .banner .cell:last-child::before {
    left: 0;
  }
}
.home .banner .cell-image {
  min-height: 40vh;
}
@media (min-width: 992px) {
  .home .banner .cell-content {
    display: none;
  }
}
.home .banner .col,
.home .banner .cell-content {
  text-align: right;
}
.home .banner .col h3,
.home .banner .cell-content h3 {
  font-family: "Avenir LT W01 95 Black", "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .home .banner .col h3,
.home .banner .cell-content h3 {
    font-size: calc(1.325rem + 0.9vw);
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .home .banner .col h3,
.home .banner .cell-content h3 {
    font-size: 2rem;
  }
}
.home .banner .col h5,
.home .banner .cell-content h5 {
  position: relative;
  padding: 0.5rem 2rem 0 0;
  margin-bottom: 0;
}
.home .banner .col h5 a,
.home .banner .cell-content h5 a {
  position: absolute;
  bottom: 0;
  left: auto;
  top: 0;
  right: 0;
  content: "";
  max-height: 3.5rem;
  padding: 0.75rem;
  background: url("../../images/gml-arrow.svg") no-repeat;
  background-size: contain;
}

.contact-card .contact-card-content {
  min-width: var(--content-width, 25rem);
}

.modal .modal-header {
  padding-top: 0;
  padding-bottom: 0;
}
.modal .modal-title {
  position: relative;
  display: flex;
  font-weight: 900;
  margin-bottom: 3rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}
@media (min-width: 992px) {
  .modal .modal-title {
    padding-top: 3rem;
  }
}
.modal .modal-title::before {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: auto;
  display: block;
  content: "";
  border-left: 3px solid #8db533;
}

#contact-form,
#fab-contact-form {
  margin-bottom: 1.5rem;
}
#contact-form h3,
#fab-contact-form h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  #contact-form h3,
#fab-contact-form h3 {
    font-size: 1.5rem;
  }
}
#contact-form .message-section .label,
#fab-contact-form .message-section .label {
  display: none;
}
#contact-form .message-section .form-control:is(textarea),
#fab-contact-form .message-section .form-control:is(textarea) {
  height: 280px;
}