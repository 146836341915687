.content-grid {
  .cell {
    position : relative;
  }

  .cell-image {
    height    : 100%;
    max-width : 100%;
    overflow  : hidden;

    img {
      object-fit      : cover;
      object-position : center center;
      width           : 100%;
      height          : 100%;
    }
  }

  .cell-content {}
}

.content-grid.banner {
  .cell {
    --cell-padding : 0;
    padding        : var(--cell-padding);

    @include media-breakpoint-up(md) {
      &:first-child {
        // --cell-padding-right : 0.5rem;
        padding-right : var(--cell-padding-right);

        .cell-content {
          right        : 0;
          padding-left : 3rem;
        }
      }

      &:not(:first-child) {
        // --cell-padding-left : 0.5rem;
        padding-left : var(--cell-padding-left);
      }
    }

    & + .cell {
      margin-top : 0.5rem;

      @include media-breakpoint-up(md) {
        margin-top : 0;
      }
    }
  }

  .cell-content {
    position         : absolute;
    bottom           : 0;
    display          : flex;
    flex-direction   : column;
    justify-content  : flex-end;
    color            : white;
    background-color : fade-out($secondary, 0.25);
    padding          : 1.5rem;
    margin-right     : var(--cell-padding-right, 0);
    width            : 100%;


    @include media-breakpoint-up(md) {
      min-height: 50%;
    }

    @include media-breakpoint-up(lg) {
      width      : auto;
      min-height : 65%;
      padding    : 2rem 3rem;
    }
  }
}
