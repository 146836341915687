@import "type-check";
@import "device-queries";
@import "shapes";

/** Typography utilities */
@mixin mark-text($color: $primary, $width: 10rem, $strength: 1rem, $opacity: 0.4, $offset: 0) {
  position       : relative;
  padding-bottom : $strength/4;

  &::before {
    @include set-position("bottom");

    display          : block;
    content          : "";
    height           : $strength;
    background-color : fade-out($color, 1-$opacity);
    width            : $width;
  }
}

/** Shortcuts */
@mixin all-containers {
  & > .container,
  & > .container-fluid {
    @content;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    & > .container#{breakpoint-infix($breakpoint, $container-max-widths)} {
      @content;
    }
  }
}

/** Flex utilities */
@mixin flex($align: "", $justify: "center", $display: "flex") {
  display : #{$display};
  @if $align { align-items : #{$align}; }
  @if $justify { justify-content : #{$justify}; }
}

/** Position utilities */
@mixin set-position($mode: "full", $position: "absolute") {
  position : #{$position};
  @if $mode == "top" { bottom : auto; } @else { bottom : 0; }
  @if $mode == "right" { left : auto; } @else { left : 0; }
  @if $mode == "bottom" { top : auto; } @else { top : 0; }
  @if $mode == "left" { right : auto; } @else { right : 0; }

  @if $mode == "full" or $mode == "top" or $mode == "bottom" {
    width : 100%;
  }
}

@mixin set-center {
  position  : absolute;
  top       : 50%;
  left      : 50%;
  transform : translate(-50%, -50%);
}

@mixin line-clamp($lines: 2, $line-height: 1.5rem) {
  display            : -webkit-box;
  -webkit-line-clamp : $lines;
  -webkit-box-orient : vertical;
  min-height         : $lines * $line-height;
  overflow           : hidden;
}

/** Effects */
@mixin hover-scale-image($scale: 1.1, $duration: 0.25s, $transitionOnHover: false) {
  overflow : hidden;

  @if ($transitionOnHover == false) {
    img { transition : transform $duration ease-in-out; }
  }

  &:hover, &:focus, &:active {
    img {
      transform  : scale($scale);
      transition : transform $duration ease-in-out;
    }
  }
}

@mixin visibility($mode: '', $duration: '', $opacity: null) {
  @if $mode == 'visible' {
    visibility : visible;
    opacity    : if($opacity, $opacity, 1);
  }

  @if $mode == 'invisible' or $mode == 'hidden' {
    visibility : hidden;
    opacity    : if($opacity, $opacity, 0);
  }

  @if is-duration($duration) {
    transition : visibility $duration ease-in-out, opacity $duration ease-in-out;
  }
}

@mixin hover-fade-in($duration: 0.25s) {
  @include visibility('invisible', $duration);

  &:hover, &:focus, &:active {
    @include visibility('visible');
  }
}

// Transitions
//
// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
}

  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
}
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

