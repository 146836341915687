/*
 * Global utility styles
 */
.bg-tertary { background-color : darken($body-bg, 2.5%); }

//.bg-theme-1 { background-color: $theme-1; }
//.bg-theme-2 { background-color: $theme-2; }
//.bg-theme-3 { background-color: $theme-3; }
//.bg-theme-4 { background-color: $theme-4; }
//.bg-theme-5 { background-color: $theme-5; }

.btn-circle { border-radius : 50% !important; }

.btn-animated {
  display   : block;
  font-size : 1.5rem;
  padding   : 0;

  .btn-inner {
    @include flex("center");
    position       : relative;
    flex-wrap      : wrap;
    padding        : 1rem;
    pointer-events : none;
    z-index        : 900;
  }

  &::before,
  &::after {
    content    : '';
    width      : 0;
    transition : width 0.1s ease-in-out;
    z-index    : 800;
  }

  &.left::before {
    @include set-position("left");
  }

  &.top::before {
    @include set-position("top");
    bottom : 100%;
  }

  &.right::after {
    @include set-position("right");
  }

  &.bottom::after {
    @include set-position("bottom");
    top : 100%;
  }


  @media screen and (hover : hover) {
    &.left:hover {
      &::before { width : 100%; }
    }
    &.top:hover {
      &::before { bottom : 0; }
    }

    &.right:hover {
      &::after { width : 100%; }
    }

    &.bottom:hover {
      &::after { top : 0; }
    }
  }
}

.scroll-lock {
  overflow-y : hidden;
}

// Responsve Headlines
.responsive-h1 {
  font-weight : 900;
  font-size   : calc(0.65 * #{$h1-font-size} + 0.5vw);
}

.responsive-h2 {
  font-size : calc(0.75 * #{$h2-font-size} + 0.5vw);
}

.responsive-h3 {
  font-size : calc(0.75 * #{$h3-font-size} + 0.5vw);
}

// Flex classes
.flex-center { @include flex("center"); }

.flex-around-center { @include flex("center", "space-around"); }

.flex-between-center { @include flex("center", "space-between"); }

// Position classes
.set-top { @include set-position("top"); }

.set-right { @include set-position("right"); }

.set-bottom { @include set-position("bottom"); }

.set-left { @include set-position("left"); }

.set-full { @include set-position("full"); }

.set-center { @include set-center; }

.fa-rotate-45 { transform : rotate(45deg); }

// Polaroid image
.img-polaroid {
  border        : 5px solid white;
  border-radius : 3px;

  & + figcaption {
    background-color           : white;
    min-height                 : 48px;
    margin-top                 : -3px;
    border-bottom-left-radius  : 3px;
    border-bottom-right-radius : 3px;
  }
}

.parallax {
  background      : $dark no-repeat 50% fixed;
  background-size : cover;
}

.zoom-image {
  overflow : hidden;

  &:hover img { transform : scale(1.05); }

  img { transition : all 0.25s ease-in-out; }
}

[class^="col-count-"],
[class*=" col-count-"] {
  column-gap   : 0.5rem;
  column-width : 250px;
  column-count : var(--col-count, 1);

  @include media-breakpoint-up(md) {
    --col-count : 2;
  }
}

@include media-breakpoint-up(lg) {
  .col-count-3 { --col-count : 3; }

  .col-count-4 { --col-count : 4; }

  .col-count-5 { --col-count : 5; }

  .col-count-6 { --col-count : 6; }
}

.responsive-image {
  width  : 100%;
  height : 100%;

  @supports (aspect-ratio: 1) {
    & > img,
    & > iframe {
      display      : block;
      aspect-ratio : var(--aspect-ratio, 1);
      margin       : 0 auto;

      @media screen and (min-width : 960px) {
        aspect-ratio : calc(var(--aspect-ratio, 1) / var(--desktop-adjustment, 1));
      }
    }
  }

  @supports not (aspect-ratio: 1) {
    position    : relative;
    padding-top : calc(100% / var(--aspect-ratio, 1));

    @include media-breakpoint-up(lg) {
      padding-top : calc(var(--desktop-adjustment) * 100% / var(--aspect-ratio, 1));
    }

    & > img,
    & > iframe {
      @include set-position("full");
    }
  }
}
