.skill-sets {
  .skill-set {
    dl { margin-bottom : 0; }

    dt, dd {
      @include media-breakpoint-up(md) {
        padding-top    : .5rem;
        padding-bottom : .5rem;
        margin-bottom  : 0;
      }
    }
  }

  .set-title {
    &::after {
      display       : block;
      width         : 50%;
      content       : "";
      border-bottom : 1px solid $primary;
    }
  }

  .progress {
    margin : 6px 0;

    .progress-bar {
      text-align : left;
      padding    : 0 $grid-gutter-width/4;
      transition : width 1s ease;
    }
  }
}
