@mixin NAVBAR-TOGGLER($icon-color: blue, $style: 'inline') {
  .navbar-toggler {
    @if $style == 'block' {
      display    : block;
      width      : 100%;
      flex-basis : 100%;
    }

    padding : 1.5rem 0;
    border  : 0;

    &:focus {
      outline : none;
    }

    &.collapsed {
      .icon-bars {
        &::before,
        &::after,
        & .icon-bar {
          transform : none;
          opacity   : 1;
        }
      }
    }

    .icon-bars {
      display        : flex;
      flex-direction : column;
      width          : 24px;
      align-items    : flex-end;
      pointer-events : none;

      &::before,
      &::after,
      .icon-bar {
        display          : block;
        width            : 100%;
        margin           : 2px 0;
        content          : '';
        background-color : $icon-color;
        border           : 1px solid $icon-color;
        transition       : all 0.25s ease-in-out;
      }

      .icon-bar {
        opacity : 0;
      }

      &::before {
        transform        : rotate(45deg);
        transform-origin : 25% 0;
      }

      &::after {
        transform        : rotate(-45deg);
        transform-origin : 5% 0;
      }
    }
  }
}
