@mixin triangle($size, $color, $direction) {
  height            : 0;
  width             : 0;

  $width            : nth($size, 1);
  $height           : nth($size, length($size));

  $foreground-color : nth($color, 1);
  $background-color : if(length($color) == 2, nth($color, 2), transparent);

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width        : $width / 2;
    $height       : if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-left   : $width solid $background-color;
      border-right  : $width solid $background-color;
      border-bottom : $height solid $foreground-color;

    } @else if $direction == right {
      border-top    : $width solid $background-color;
      border-bottom : $width solid $background-color;
      border-left   : $height solid $foreground-color;

    } @else if $direction == down {
      border-left   : $width solid $background-color;
      border-right  : $width solid $background-color;
      border-top    : $height solid $foreground-color;

    } @else if $direction == left {
      border-top    : $width solid $background-color;
      border-bottom : $width solid $background-color;
      border-right  : $height solid $foreground-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top    : $height solid $foreground-color;

    @if $direction == up-right {
      border-left  : $width solid $background-color;

    } @else if $direction == up-left {
      border-right : $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom : $height solid $foreground-color;

    @if $direction == down-right {
      border-left  : $width solid $background-color;

    } @else if $direction == down-left {
      border-right : $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-width  : $height $width;
    border-style  : solid;
    border-color  : $background-color $background-color $foreground-color;
  } @else if ($direction == inset-down) {
    border-width  : $height $width;
    border-style  : solid;
    border-color  : $foreground-color $background-color $background-color;
  } @else if ($direction == inset-right) {
    border-width  : $width $height;
    border-style  : solid;
    border-color  : $background-color $background-color $background-color $foreground-color;
  } @else if ($direction == inset-left) {
    border-width  : $width $height;
    border-style  : solid;
    border-color  : $background-color $foreground-color $background-color $background-color;
  }
}


/*
 * CSS icons
 */
// CSS arrow mixins
@mixin arrow-up($dim, $length: 0, $color: $primary) {
  content      : '';
  width        : 0;
  height       : 0;
  border-left  : $dim solid transparent;
  border-right : $dim solid transparent;
  @if $length != 0 { border-bottom : $length solid $color; } @else { border-bottom : $dim solid $color; }
}

@mixin arrow-down($dim, $length: 0, $color: $primary) {
  content      : '';
  width        : 0;
  height       : 0;
  border-left  : $dim solid transparent;
  border-right : $dim solid transparent;
  @if $length != 0 { border-top : $length solid $color; } @else { border-top : $dim solid $color; }
}

@mixin arrow-left($dim, $length: 0, $color: $primary) {
  content       : '';
  width         : 0;
  height        : 0;
  border-top    : $dim solid transparent;
  border-bottom : $dim solid transparent;
  @if $length != 0 { border-right : $length solid $color; } @else { border-right : $dim solid $color; }
}

@mixin arrow-right($dim, $length: 0, $color: $primary) {
  content       : '';
  width         : 0;
  height        : 0;
  border-top    : $dim solid transparent;
  border-bottom : $dim solid transparent;
  @if $length != 0 { border-left : $length solid $color; } @else { border-left : $dim solid $color; }
}

//CSS square mixin
@mixin square($dim, $color: $primary) {
  content          : '';
  width            : $dim;
  height           : $dim;
  background-color : $color;
}
