.modal {
  .modal-header {
    .container-fluid {
      display         : inherit;
      align-items     : inherit;
      justify-content : inherit;
      flex-shrink     : inherit;
    }

    .btn-close:focus { box-shadow : none; }
  }

  .modal-content {}

  .modal-full {
    @include media-breakpoint-up(sm) {
      margin    : 0 auto;
      max-width : 1500px;
    }

    .modal-content {
      border : none;
    }

    .modal-body {
      padding : 0;
      border  : $modal-content-border-width solid $modal-content-border-color;
    }
  }
}

.modal-light {}

.modal-dark,
.modal-black {
  .modal-content {
    color : $light;
  }
}
