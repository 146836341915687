.cards,
.gallery {
  .row { width : 100%; }

  .col {
    &:last-child {
      .card {
        @include media-breakpoint-down(xs) {
          margin-bottom : 0;
        }
      }
    }
  }

  .card-group {}

  .card-group {
    &.body-overlay {
      .card-body {
        @include set-position("bottom");
        padding : $grid-gutter-width/2;
      }
    }
  }
}

.card {
  .card-overlay {
    @include set-position("full");
    @include visibility('hidden', 0.25s);
  }

  &:hover, &:focus, &:active {
    .card-overlay {
      background-color : $card-overlay-bg;

      @include visibility('visible', '', 0.25);
    }
  }

  .card-content {
    position : relative;

    &.card-img-overlay {
      position       : absolute;
      @include flex("center");
      flex-direction : column;
    }
  }
}
