// Logo
//
//  Settings for the page logo.
//
$logo-mobile-width           : 4.5rem;
$logo-desktop-width          : 9.5rem;

// Theme Typography
//
$h1-font-family              : "Avenir LT W01 95 Black", $font-family-base;
$h2-font-family              : "Avenir LT W01 95 Black", $font-family-base;
$h3-font-family              : "Avenir LT W01 65 Medium", $font-family-base;
$h4-font-family              : null;
$h5-font-family              : "Avenir LT W01 65 Medium", $font-family-base;
$h6-font-family              : null;


// Header
//
//  Settings for the `<header>` element.
//
$header-bg                   : $secondary;
$header-color                : $gray-800;
$header-link-color           : $header-color;
$header-link-hover-color     : $header-color;
$header-border-color         : white;
$header-height               : $logo-desktop-width;
$header-mobile-height        : $logo-mobile-width;

$header-shadow               : false;
$header-shadow-color         : $dark;

$header-transition           : true;

$topbar-nav-link-color       : $light;
$topbar-nav-link-hover-color : darken($topbar-nav-link-color, 15%);

// Body
//
//  Settings for the page body.
//
$spacer-bg                   : $dark;
$grid-float-breakpoint       : lg;

// Footer
//
//  Settings for the `<footer>` element.
//
$footer-bg                   : $primary;
$footer-color                : white;
$footer-link-color           : $footer-color;
$footer-link-hover-color     : fade-out($footer-color, 0.35);
$footer-border-color         : white;

:root {
  --swiper-theme-color  : #{$primary};
  //--swiper-navigation-color: #{$primary};

  --bs-body-font-family : "Avenir LT W01 55 Roman", #{$font-family-base};
  --bs-body-font-size   : #{$body-font-size};
}
