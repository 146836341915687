
.portfolio-container {
  .card {
    .card-content {
      padding : 0;
    }

    .card-body {
      @include set-position('full');
      @include flex('center');

      flex-direction   : column;
      font-weight      : bold;
      color            : $yellow;
      background-color : rgba(0, 0, 0, 0.5);
      text-shadow      : 1px 1px #3b3535;
      padding          : 0;

      @include visibility('invisible', 0.25s);
    }

    &:hover, &:focus, &:active {
      .card-body {
        @include visibility('visible');
      }
    }
  }
}
