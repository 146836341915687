.langswitcher {
  --langswitcher-nav-link-color        : #{$nav-link-color};
  --langswitcher-nav-link-hover-color  : #{$nav-link-hover-color};
  --langswitcher-nav-link-border-color : #{$nav-link-color};

  display                              : flex;
  gap                                  : 0.5rem;
  margin-bottom                        : 0;
  padding                              : 0;
  list-style                           : none;

  .langswitcher-item + .langswitcher-item {
    &::before {
      content      : "";
      border-left  : 1px solid var(--langswitcher-nav-link-border-color);
      margin-right : 0.5rem;
    }
  }

  a {
    // font-weight     : bold;
    text-decoration : none;
    color           : var(--langswitcher-nav-link-color);

    &:hover, &:focus, &:active {
      color : var(--langswitcher-nav-link-hover-color);
    }

    &.active {
      text-decoration : underline;
    }
  }
}
