.section-fade-in {
  @include set-position("full");
  opacity          : 0;
  background-color : white;
  transition       : opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  z-index          : 1000;

  &:not(.show) {
    top            : -100px;
    height         : 0;
    bottom         : auto;
    pointer-events : none;
  }

  &.show {
    opacity        : 1;
    height         : 100%;
    pointer-events : initial;
  }

  .section-header {
    display         : flex;
    justify-content : flex-end;

    .close {
      float            : none;
      padding          : 8px 13px;
      margin           : 0.5rem;
      color            : white;
      background-color : $primary;
      border-color     : $primary;
      opacity          : 1;

      &:hover, &:focus, &:active {
        color : white;
      }
    }
  }
}

.social.nav {
  .nav-item {
    padding : 0.375rem;
  }
}

.text {
  .article-image {
    display     : none;
    border-left : 1px solid $primary;

    @include media-breakpoint-up(sm) {
      display     : flex;
      align-items : flex-start;
      flex-basis  : 33.3333333333%;
    }

    @include media-breakpoint-up(lg) {
      border      : 0;
      align-items : center;
    }
  }
}

#grav-login {
  .form-field {
    text-align : center;

    & + .form-field {
      margin-top : 1rem;
    }
  }

  .form-label { display : none; }

  .form-actions {
    margin-top : 1rem;
    text-align : center;
  }
}
