@use "sass:math";

$gml-border-decoration-width : 3px;

@mixin gml-title($padding-top) {
  position      : relative;
  display       : flex;
  // color         : $dark;
  font-weight   : 900;
  margin-bottom : 3rem;
  padding-top   : math.div($padding-top, 2);
  padding-left  : 1.5rem;

  @include media-breakpoint-up($grid-float-breakpoint) {
    padding-top : $padding-top;
  }

  &::before {
    @include set-position("left");

    display     : block;
    content     : "";
    border-left : $gml-border-decoration-width solid $primary;
  }
}

body {
  margin-bottom : 3.25rem;

  @include media-breakpoint-up($grid-float-breakpoint) {
    margin-bottom : 0;
  }
}

.module-title {
  @include gml-title(6rem);
}

#main-topbar {
  display : none;

  @include media-breakpoint-up($grid-float-breakpoint) {
    display : block;
  }

  .topbar-nav {
    margin-left : auto;

    .nav-item {
      display : inline-flex;
    }

    .nav-item + .nav-item {
      $nav-item-separator-margin : 0.14375rem;
      &::before {
        content                    : "";
        border-left                : 1px solid $light;
        margin-top                 : $nav-item-separator-margin;
        margin-bottom              : $nav-item-separator-margin;
      }
    }
  }
}

#main-nav {
  @include media-breakpoint-up($grid-float-breakpoint) {
    @include all-containers {
      align-items : flex-end;
    }
  }

  .langswitcher,
  .navbar-collapse {
    @include media-breakpoint-up($grid-float-breakpoint) {
      margin-bottom : 1.5rem;
    }
  }

  .langswitcher {
    @include media-breakpoint-up($grid-float-breakpoint) {
      display : none;
    }
  }

  .navbar-module {
    position        : relative;
    // display         : flex;
    flex-direction  : column;
    justify-content : flex-end;
    align-self      : stretch;
    color           : lighten($dark, 10%);
    padding-left    : 1.5rem;
    margin-bottom   : 2rem;

    &::before {
      @include set-position("left");

      display     : block;
      content     : "";
      border-left : $gml-border-decoration-width solid $primary;
    }

    .navbar-module-label {
      text-transform : uppercase;
      font-size      : 80%;
    }

    .phone-link {
      text-decoration : none;
      font-weight     : bold;
      color           : lighten($dark, 10%);
      letter-spacing  : 1px;

      &:hover, &:focus, &:active {
        text-decoration : underline;
      }
    }
  }

  .navbar-nav {
    margin-left : 15%;
    flex        : 1 0 auto;
  }

  .nav-link {
    &.active {
      &::after {
        position        : absolute;
        top             : -0.25rem;
        right           : -0.5rem;
        content         : "";
        width           : 1.25rem;
        aspect-ratio    : 1;
        background      : url("../../images/gml-blatt.png") no-repeat center center;
        background-size : contain;

      }
    }
  }

  .login { display : none; }
}

.contact-block {
  border-left : 3px solid $primary;
  margin      : 0 0 3rem;

  .phone-link {
    color           : inherit;
    text-decoration : none;

    &:hover, &:focus, &:active {
      text-decoration : underline;
    }
  }
}

.footer-columns {
  .nav-link {
    // padding-left: 0;

    padding : $nav-link-padding-y/2 $nav-link-padding-x $nav-link-padding-y/2 0;
    color   : $light;

    &:hover, &:focus, &:active {
      color : $gray-500;
    }
  }

  .footer-contact {
    p {
      line-height   : 2;
      margin-bottom : 2rem;
    }
  }
}

#bottom-mobile-nav {
  &.flex-column {
    width           : 80%;
    height          : 80%;
    justify-content : space-around;
    align-items     : flex-end;
  }

  .nav-link {
    font-size : 150%;
    color     : $body-color;
  }
}

/**
 * GML-specific styles
*/

#page-banner,
.gml-banner {
  padding-bottom : 0;

  .caption-top-right .carousel-caption {
    @include media-breakpoint-down(md) {
      top    : auto;
      bottom : 0;
    }
  }

  .caption-body {
    background-color : fade-out($secondary, 0.25);
    padding          : 2rem;
    text-align       : right;

    @include media-breakpoint-up(lg) {
      min-width : 25%;
    }

    h3 {
      @include font-size($h1-font-size);
      font-weight    : 700;
      text-transform : uppercase;

      &:only-child { margin-bottom : 0; }
    }
  }
}

.gml-features {
  --swiper-navigation-size : 1.5rem;

  .card {
    .card-header {
      position         : absolute;
      top              : 0;
      left             : 0;
      display          : flex;
      justify-content  : space-between;
      align-items      : center;
      width            : 100%;
      border           : none;
      color            : white;
      background-color : fade-out($secondary, 0.25);
      padding          : 1.5rem;
      transform        : translateY(-100%);

      @include media-breakpoint-up(md) {
        width : auto;
      }
    }

    .card-header-appendix {
      @include media-breakpoint-up(md) {
        display : none;
      }
    }

    .card-body {
      padding-left  : 0;
      padding-right : 0;
    }
  }

  .carousel-container {
    background-color : #8DB5331A;
    padding-top      : calc(var(--bs-gutter-x) * 1);
    padding-bottom   : calc(var(--bs-gutter-x) * 1);

    @include media-breakpoint-up(md) {
      background-color : transparent;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width            : 3.5rem;
    height           : 3.5rem;
    color            : white;
    background-color : $primary;
    top              : 25%;

    &.swiper-button-disabled {
      opacity : 0.25;
    }
  }

  .swiper-button-prev {
    left : 0;
  }

  .swiper-button-next {
    right : 0;
  }


  .swiper-pagination {
    bottom : -0.25rem;
  }
}

#gml-technology {
  padding-top : 3rem;
}

#services-cta {
  .services-cta-btn {
    padding : 1rem 1.5rem;
  }
}

.home {
  .banner {
    position : relative;

    .intro-text {
      @include set-position("bottom");
      display : none;
      z-index : 100;

      @include media-breakpoint-up(lg) {
        display : block;
      }

      .row {
        color            : white;
        background-color : fade-out($secondary, 0.25);
      }

      .col {
        @include flex("", "flex-end");

        flex-direction : column;
        padding        : 3rem;
        text-align     : right;

        &:first-child {
          padding-right : 8.5rem;
        }

        &:last-child {
          padding-left : 0;
          text-align   : left;
        }
      }
    }

    .cell {
      &:last-child {
        &::before {
          @include media-breakpoint-up(md) {
            @include set-position("left");

            left             : -0.5rem;
            content          : "";
            padding          : 0.5rem;
            background-color : var(--bs-body-bg);
            z-index          : 150;
          }

          @include media-breakpoint-up(lg) {
            left : 0;
          }
        }
      }
    }

    .cell-image {
      min-height : 40vh;
    }

    .cell-content {
      @include media-breakpoint-up(lg) {
        display : none;
      }
    }

    .col,
    .cell-content {
      text-align : right;

      h3 {
        font-family    : $h1-font-family;
        text-transform : uppercase;

        @include media-breakpoint-up(xl) {
          @include font-size($h2-font-size);
        }
      }

      h5 {
        position      : relative;
        padding       : 0.5rem 2rem 0 0;
        margin-bottom : 0;

        a {
          @include set-position("right");

          content         : "";
          max-height      : 3.5rem;
          padding         : 0.75rem;
          background      : url("../../images/gml-arrow.svg") no-repeat;
          background-size : contain;
        }
      }
    }
  }
}

.contact-card {
  .contact-card-content {
    min-width : var(--content-width, 25rem);
  }
}

.modal {
  .modal-header {
    padding-top    : 0;
    padding-bottom : 0;
  }

  .modal-title {
    @include gml-title(3rem);
  }
}

#contact-form,
#fab-contact-form {
  margin-bottom : 1.5rem;

  h3 {
    @include font-size($h4-font-size);
  }

  .message-section {
    .label { display : none; }

    .form-control:is(textarea) {
      height : 280px;
    }
  }
}
